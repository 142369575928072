import { Component, DestroyRef, ViewChild } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';
import { App } from '@capacitor/app';
import { from, Observable, of, Subscription, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SplashScreen } from '@capacitor/splash-screen';

// Pour Swiper.js
register();
@Component({
  selector: 'pro-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  constructor(
    private readonly platform: Platform,
    private readonly alertController: AlertController,
    private readonly destroyRef: DestroyRef
  ) {
    this.handleQuitApp();
    this.initializeApp();
  }

  private initializeApp() {
    from(this.platform.ready())
      .pipe(
        switchMap(() => SplashScreen.hide()),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private handleQuitApp(): void {
    this.handleHardwareBackButton()
      .pipe(
        switchMap((canQuit) => (canQuit ? App.exitApp() : of(void 0))),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private handleHardwareBackButton(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      let alertRef: HTMLIonAlertElement;
      const subscription: Subscription = new Subscription();
      subscription.add(
        this.platform.backButton.subscribeWithPriority(-1, () => {
          if (!this.routerOutlet.canGoBack()) {
            subscription.add(
              from(
                this.alertController.create({
                  header: `Voulez-vous quittez l'application ?`,
                  cssClass: 'custom-alert',
                  buttons: [
                    {
                      text: 'Non',
                      role: 'cancel',
                      handler: () => {
                        subscriber.next(false);
                      }
                    },
                    {
                      text: 'Oui',
                      role: 'confirm',
                      handler: () => {
                        subscriber.next(true);
                      }
                    }
                  ]
                })
              )
                .pipe(
                  switchMap((alert) => {
                    alertRef = alert;
                    return alert.present();
                  })
                )
                .subscribe()
            );
          }
        })
      );
      subscriber.add(() => {
        subscription.unsubscribe();
        if (alertRef) {
          alertRef.dismiss();
        }
      });
    });
  }
}

import { NgModule } from '@angular/core';
import { AuthClient } from './communications/services/auth.client';
import { AuthMapper } from './communications/mappers/auth.mapper';
import { AuthDataAccess } from './communications/data-access/auth.data-access';
import { JwtTokenService } from './services/jwt-token.service';
import { KeepAliveService } from './services/keep-alive.service';

@NgModule({
  providers: [AuthClient, AuthMapper, AuthDataAccess, JwtTokenService, KeepAliveService]
})
export class AuthModule {}

export abstract class RoomApiUrl {
  /**
   * Liste des url d'api
   */
  static readonly getRoomById = 'room/join-id';
  static readonly getRoomJoinIdFromOwner = 'room/%id/join-id';
  static readonly getConnectionRequestFromOwner = 'room/%id/connectionRequest';
  static readonly getRoomFromConnectionRequest = 'room/connectionRequest/%id';
  static readonly joinRoom = 'room/join';
  static readonly getRoomsByMember = 'room/member';
  static readonly getOwnedRooms = 'room/owned';
  static readonly getRoomsByPendingMember = 'room/pending-user';
  static readonly getAllPendingUserByOwnedRoom = 'room/all-pending-user-by-owned-room';
  static readonly pendingUserAgreement = 'room/%id/pending-user-agreement';
  static readonly leaveRoom = 'room/%id/leave';
  static readonly BanUser = 'room/%id/ban';
  static readonly deleteRoom = 'room/%id/delete';
  static readonly updateRoomAvatar = 'room/%id/avatar';
  static readonly updateRoomPassword = 'room/%id/password';
  static readonly updateRoomDescription = 'room/%id/description';
  static readonly createRoom = 'room';
  static readonly lockRoom = 'room/%id/lock';
  static readonly unlockRoom = 'room/%id/unlock';
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { MessageUnionModel } from '@proxima/common';
import { map, tap } from 'rxjs/operators';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';

@Injectable()
export class UnreadMessageService {
  private canIncrementCounter = true;
  private unreadMessageCount$ = new BehaviorSubject<number>(0);

  constructor(private readonly userDataAccess: UserDataAccess) {}

  public getUnreadMessageCount(): Observable<number> {
    return this.unreadMessageCount$.asObservable();
  }

  public addNewMessage(message: MessageUnionModel): Observable<void> {
    return this.userDataAccess.getUserId().pipe(
      take(1),
      tap((userId: string) => {
        if (this.canIncrementCounter && userId !== message.from.id) {
          this.unreadMessageCount$.next(this.unreadMessageCount$.value + 1);
        }
      }),
      map(() => void 0)
    );
  }

  public resetUnreadMessageCount(): void {
    this.unreadMessageCount$.next(0);
  }

  public setCanIncrement(canIncrement: boolean): void {
    this.canIncrementCounter = canIncrement;
  }
}

import { MessageTreeModel } from '@business/message/data-access/state/message-state';
import { MessageUnionModel } from '@proxima/common';

export class FetchRoomMessages {
  static readonly type = '[Message] Fetch room messages';
  constructor(public readonly fetchedMessages: MessageTreeModel, public readonly treeId: string) {}
}

export class NewRoomMessage {
  static readonly type = '[Message] New room message';
  constructor(public readonly currentUserId: string, public readonly newRoomMessage: MessageUnionModel) {}
}

export abstract class RoomEvents {
  static roomSubscribe = 'room:subscribe';
  static roomUpdate = 'room:update';
  static roomJoin = 'room:join';
  static roomPendingJoin = 'room:pending:join';
  static roomLeave = 'room:leave';
  static roomDisconnect = 'room:disconnect';
  static roomDeleteMessages = 'room:delete_messages';
  static roomDeleteMessage = 'room:delete_message';
  static roomDelete = 'room:delete';
  static roomPendingOwnerAgreement = 'room:pending:owner:Agreement';
  static roomPendingAccess = 'room:pending:join';
  static roomGetAllPendingUser = 'room:get:all:pending:user';
  static roomBanUser = 'room:ban:user';
  static roomUpdateDescription = 'room:update:description';
  static roomUpdateAvatar = 'room:update:avatar';
  static roomEmergencyAlert = 'room:emergency:alert';
}

export abstract class UserEvents {
  static userSubscribe = 'user:subscribe';
  static userGeoLocation = 'user:geolocation';
  static usersGeoLocation = 'users:geolocation';
  static userUpdateAvatar = 'user:update:avatar';
}

export abstract class MessageEvents {
  static deleteDirectMessages = 'direct:delete_messages';
  static deleteDirectMessage = 'message:direct:delete_message';
  static direct = 'message:direct';
  static directTyping = 'message:direct:typing';
  static deleteRoomMessage: 'message:room:delete';
  static createRoomMessage = 'message:room:create';
  static roomTyping = 'message:room:typing';
}

export abstract class AudioRtcEvents {
  static offer = 'audio:rtc:offer';
  static answer = 'audio:rtc:answer';
  static callerCandidate = 'audio:rtc:caller:candidate';
  static calleeCandidate = 'audio:rtc:callee:candidate';
  static stopCommunication = 'audio:rtc:stop';
  static userSpeaking = 'audio:rtc:user:speaking';
}

export abstract class AuthEvents {
  static refreshToken = 'refresh:token';
}

export abstract class MiscEvents {
  /**
   * Evenements emits lorsque un utilisateur envoi une requête par websocket et attend une réponse en retour
   * et que celle-ci échoue.
   *
   */
  static exception = 'exception';
  static reconnecting = 'reconnect_attempt';
  static reconnection = 'reconnect';
  static connect = 'connect';
  static disconnect = 'disconnect';
}

/**
 * Une couleur est réparti entre 3 valeur distinctes : R (rouge) V (vert) B (bleu)
 * Elle peut être représentée sous le format #FFFFFF
 * Le '#' détermine que c'est une couleur
 * les 3 FF qui suivent correspondent à la valeur de chaque dominante (RGB) sur 2 octets (256 valeurs différentes)
 *
 * Ici on  utilise le format HSL pour Hue (teinte) Saturation et Luminosité
 *
 * La teinte est défini par une valeur comprise entre 0 et 360
 * La saturation et la luminosité sont des pourcentages
 * Pour maintenir des couleur lumineuse c'est plus pratique pour cela
 * On établi ici une saturation à 100% pour avoir des couleur vives, une luminosité à 50% (voir pour rendre dynamique cette valeur)
 * et la teinte est aléatoire.
 *
 * Un petit ajustement est effectué pour les valeurs dont la teinte est comprise entre 215 et 265 correspondant au bleu
 * qui est une couleur difficilement identifiable par l'oeil humain. Dans ce cas on augmente la luminosité.
 *
 * Une fois la couleur défini au format HSL on la retransforme au format RGB puis au format héxadécimal
 */
export const generateRandomColor = () => {

  const max = 341;
  const hue = Math.floor(Math.random() * max); // between 0 and 340
  let saturation = 100;
  let lightness = 50;

  // color adjustment:
  if (hue > 215 && hue < 265) {
    const gain = 20;
    const blueness = 1 - Math.abs(hue - 240) / 25;
    const change = Math.floor(gain * blueness);
    lightness += change;
    saturation -= change;
  }
  return hsl2rgb(hue, saturation, lightness);
};

const hsl2rgb = (hue: number, saturation: number, lightness: number): string => {
  const l = lightness / 100;
  const a = saturation * Math.min(l, 1 - l) / 100;
  const f = (n: number) => {
    const k = (n + hue / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const addAlpha = (color: string, opacity: number) => {
  // coerce values so ti is between 0 and 1.
  const opacityRatio = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + opacityRatio.toString(16).toUpperCase();
};

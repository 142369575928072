import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { IonicModule } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';
import { appCompanyLogoIcon } from '../assets/svg/company-logo';
import { appSubtlePrismIcon } from '../assets/svg/subtle-prism';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { appGreenTickAnimatedIcon } from '../assets/svg/green-tick-animated';
import { MessageService } from 'primeng/api';
import { RoomEventsEffectsService } from './services/room-events-effects/room-events-effects.service';
import { LoggedInService } from '@business/authentication/services/logged-in.service';
import { AuthModule } from '@business/authentication/auth.module';
import { RoomModule } from '@business/room/communications/room.module';
import { MessageModule } from '@business/message/message.module';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { AppReadyComponent } from './presentation/pages/app-ready/app-ready.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { AvatarModule } from '@business/avatar/avatar.module';
import { UserModule } from '@business/user/user.module';
import { ToastModule } from 'primeng/toast';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
import { CapacitorHttpInterceptor } from './interceptors/capacitor-http.interceptor';
import { appQrCodeIcon } from '../assets/svg/qr-code';
import { appExpiredIcon } from '../assets/svg/expired';
import { appFailedActionIcon } from '../assets/svg/failed-action';
import { appRedCrossAnimatedIcon } from '../assets/svg/red-cross-animated';

const config: SocketIoConfig = {
  url: environment.socketEndpoint,
  options: { transports: ['websocket'], autoConnect: false, withCredentials: true, reconnection: true, reconnectionDelay: 1000 }
};

registerLocaleData(localeFr, 'fr');

// Noop handler for factory function
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => () => {};

@NgModule({
  declarations: [AppComponent, AppReadyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: 'md' }),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true
      },
      selectorOptions: {
        injectContainerState: false
      }
    }),
    NgxsResetPluginModule.forRoot(),
    AuthModule,
    RoomModule,
    AvatarModule,
    UserModule,
    MessageModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Proxima',
      latency: 0
    }),
    ToastModule
  ],
  providers: [
    MessageService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapacitorHttpInterceptor,
      multi: true
    },
    // Obligé de le déclarer avec una factory car ce service est autonome et est injecté nulle part
    // il faut donc que le tree shaking le rajoute quand même sinon il n'est pas chargé
    {
      provide: APP_INITIALIZER,
      useFactory: noop,
      deps: [RoomEventsEffectsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: noop,
      deps: [LoggedInService],
      multi: true
    },
    provideSvgIconsConfig({
      icons: [
        appCompanyLogoIcon,
        appSubtlePrismIcon,
        appGreenTickAnimatedIcon,
        appRedCrossAnimatedIcon,
        appQrCodeIcon,
        appExpiredIcon,
        appFailedActionIcon
      ],
      sizes: {
        xs: '10px',
        sm: '12px',
        md: '16px',
        lg: '20px',
        xl: '25px',
        xxl: '30px'
      },
      defaultSize: 'sm'
    }),
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { FormattedResponse, MediaModel, UserResponse } from '@proxima/common';
import { isDefined } from '@shared/typeguard/is-defined.type-guard';

@Injectable()
export class UserMapper {
  public mapFormattedUserAvatarResponse(res: FormattedResponse<MediaModel>): MediaModel {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('avatar empty');
  }

  public mapFormattedUserResponse(res: FormattedResponse<UserResponse>): UserResponse {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('user info are missing');
  }
}

import { RoomUser, SessionModel } from '@shared/models/session.models';
import { MediaModel } from '@proxima/common';
import { FocusedUserOnMapStatusEnum } from '@shared/models/enums/focused-user-on-map-status.enum';

export class AddNewSession {
  static readonly type = '[Opened Session] Add new session';
  constructor(public readonly sessionInfo: SessionModel) {}
}

export class AddConnectedUserToRoom {
  static readonly type = '[Opened Session] Add connected user to room';
  constructor(public readonly user: RoomUser) {}
}

export class RemoveConnectedUserToRoom {
  static readonly type = '[Opened Session] Remove connected user to room';
  constructor(public readonly userId: string) {}
}

export class AddMembersToRoom {
  static readonly type = '[Opened Session] Add members to room';
  constructor(public readonly members: RoomUser[]) {}
}

export class SetPendingUserGranted {
  static readonly type = '[Opened Session] Set pending user granted';
}

export class ResetConnectedUsersToRoom {
  static readonly type = '[Opened Session] Reset connected users';
}

export class RemoveUserToRoom {
  static readonly type = '[Opened Session] Remove user to room';
  constructor(public readonly userId: string) {}
}

export class QuitRoom {
  static readonly type = '[Opened Session] Quit room';
}

export class UpdateRoomDescription {
  static readonly type = '[Opened Session] Update room description';
  constructor(public readonly description: string) {}
}

export class UpdateRoomLockStatus {
  static readonly type = '[Opened Session] Update room lock status';
  constructor(public readonly shouldLock: boolean) {}
}

export class UpdateRoomAvatar {
  static readonly type = '[Opened Session] Update room avatar';
  constructor(public readonly avatar: MediaModel) {}
}

export class UpdateMemberAvatar {
  static readonly type = '[Opened Session] Update member avatar';
  constructor(
    public readonly newAvatar: MediaModel,
    public readonly userId: string
  ) {}
}

export class UpdateMemberSpeakingStatus {
  static readonly type = '[Opened Session] Update member speaking status';
  constructor(
    public readonly isSpeaking: boolean,
    public readonly userId: string
  ) {}
}

export class SetFocusedUserIdOnMap {
  static readonly type = '[Opened Session] Set focused user id on map';

  constructor(
    public readonly userId: string,
    public readonly status?: FocusedUserOnMapStatusEnum
  ) {}
}

export class SetFocusedUserStatusOnMap {
  static readonly type = '[Opened Session] Set focused user status on map';

  constructor(public readonly status: FocusedUserOnMapStatusEnum) {}
}

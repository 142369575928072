import { Injectable } from '@angular/core';
import { CsrfTokenResponse, FormattedResponse, LoginResponse } from '@proxima/common';
import { isDefined } from '@shared/typeguard/is-defined.type-guard';

@Injectable()
export class AuthMapper {
  public mapFormattedCsrfResponse(res: FormattedResponse<CsrfTokenResponse>): string {
    if (isDefined(res.result)) {
      return res.result.csrfToken;
    }
    throw new Error('csrf token is missing');
  }

  public mapFormattedLoginResponse(res: FormattedResponse<LoginResponse>): string {
    if (isDefined(res.result)) {
      return res.result.accessToken;
    }
    throw new Error('access token is missing');
  }
}

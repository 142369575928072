import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, concat, defer, forkJoin, from, Observable, of, switchMap, toArray } from 'rxjs';
import { Geolocation } from '@capacitor/geolocation';
import { map, tap } from 'rxjs/operators';
import { Microphone } from '@mozartec/capacitor-microphone';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';
import { Filesystem } from '@capacitor/filesystem';
import { Camera } from '@capacitor/camera';
import { CurrentPlatformService } from '../current-platform/current-platform.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissionRefresh$ = new BehaviorSubject<void>(void 0);
  constructor(private readonly currentPlatform: CurrentPlatformService) {}

  public getPermissionRefresh(): Observable<void> {
    return this.permissionRefresh$.asObservable();
  }

  public refreshPermissionNeeded(): void {
    this.permissionRefresh$.next(void 0);
  }

  public navigateToSettingsAndCheckPermissionAfter(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return this.navigateToSettings().pipe(switchMap(() => this.isAllPermissionsGranted()));
    }
    return of(false);
  }

  public navigateToSettings(): Observable<void> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() =>
        NativeSettings.openAndroid({
          option: AndroidSettings.ApplicationDetails
        })
      ).pipe(map(() => void 0));
    }
    return of(void 0);
  }

  public isAllPermissionsGranted(): Observable<boolean> {
    return forkJoin([
      this.isMicrophonePermissionGranted(),
      this.isLocationPermissionGranted(),
      this.isFileSystemAccessPermissionGranted(),
      this.isCameraPermissionGranted()
    ]).pipe(map((permissionsStatus) => permissionsStatus.every((p) => p)));
  }

  public requestPermissionsIfNeeded(): Observable<boolean> {
    const obs$ = [
      this.isMicrophonePermissionGranted().pipe(switchMap((isGranted) => (isGranted ? of(true) : this.requestMicrophonePermission()))),
      this.isLocationPermissionGranted().pipe(switchMap((isGranted) => (isGranted ? of(true) : this.requestLocationPermission()))),
      this.isFileSystemAccessPermissionGranted().pipe(
        switchMap((isGranted) => (isGranted ? of(true) : this.requestFileSystemAccessPermission()))
      ),
      this.isCameraPermissionGranted().pipe(switchMap((isGranted) => (isGranted ? of(true) : this.requestCameraPermission())))
    ];
    return concat(...obs$).pipe(
      toArray(),
      map((permissionsStatus) => permissionsStatus.every((p) => p))
    );
  }

  public requestFileSystemAccessPermission(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Filesystem.requestPermissions()).pipe(map((permissionStatus) => permissionStatus.publicStorage === 'granted'));
    } else {
      return of(true);
    }
  }

  public requestCameraPermission(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Camera.requestPermissions()).pipe(
        tap((permissionStatus) => console.table(permissionStatus)),
        map((permissionStatus) => permissionStatus.camera === 'granted'),
        catchError(() => {
          // avec Android 13, normalement pas besoin de permission
          // donc on redemande si malgré l'erreur la demande de permission est passé
          return this.isCameraPermissionGranted();
        })
      );
    } else {
      return from(navigator.mediaDevices.getUserMedia({ video: true })).pipe(
        map(() => true),
        catchError((err: DOMException) => {
          if (err.code === DOMException.NOT_FOUND_ERR) {
            // pas de camera dispo
            return of(true);
          }
          return of(false);
        })
      );
    }
  }

  public requestMicrophonePermission(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Microphone.requestPermissions()).pipe(
        map((permissionStatus) => permissionStatus.microphone === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return defer(() => navigator.mediaDevices.getUserMedia({ audio: true })).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
  }

  public requestLocationPermission(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Geolocation.requestPermissions({ permissions: ['location', 'coarseLocation'] })).pipe(
        map((permissions) => permissions.location === 'granted' && permissions.coarseLocation === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return new Observable<boolean>((observer) => {
        navigator.geolocation.getCurrentPosition(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            if (error.code == error.PERMISSION_DENIED) {
              observer.next(false);
            } else {
              observer.next(true);
            }
            observer.complete();
          }
        );
      });
    }
  }

  public isFileSystemAccessPermissionGranted(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Filesystem.checkPermissions()).pipe(
        map((permissionStatus) => permissionStatus.publicStorage === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return of(true);
    }
  }

  public isCameraPermissionGranted(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Camera.checkPermissions()).pipe(
        map((permissionStatus) => permissionStatus.camera === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return defer(() => navigator.mediaDevices.getUserMedia({ audio: true })).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
  }

  public isMicrophonePermissionGranted(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Microphone.checkPermissions()).pipe(
        map((permissionStatus) => permissionStatus.microphone === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return defer(() => navigator.mediaDevices.getUserMedia({ audio: true })).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
  }

  public isLocationPermissionGranted(): Observable<boolean> {
    if (this.currentPlatform.isRealAndroidDevice()) {
      return defer(() => Geolocation.checkPermissions()).pipe(
        map((permissions) => permissions.location === 'granted' && permissions.coarseLocation === 'granted'),
        catchError(() => of(false))
      );
    } else {
      return new Observable<boolean>((observer) => {
        navigator.geolocation.getCurrentPosition(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            if (error.code == error.PERMISSION_DENIED) {
              observer.next(false);
            } else {
              observer.next(true);
            }
            observer.complete();
          }
        );
      });
    }
  }
}

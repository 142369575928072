import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { combineLatest, Observable, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { JwtTokenService } from '@business/authentication/services/jwt-token.service';
import { AuthDataAccess } from '@business/authentication/communications/data-access/auth.data-access';
import { AuthenticationApiUrl } from '@business/authentication/communications/constants/api-url.constants';
import { IS_PUBLIC_API } from '@shared/constants/api-context.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private csrfToken$: Observable<string>;
  private accessToken$: Observable<string>;
  constructor(
    private readonly auth: JwtTokenService,
    private readonly dataAccess: AuthDataAccess
  ) {
    this.csrfToken$ = this.dataAccess.getCsrfToken();
    this.accessToken$ = this.auth.getAccessToken();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isCsrfRefresh = AuthenticationApiUrl.isCsrfTokenUrl(request.url);
    if (isCsrfRefresh) {
      return next.handle(request);
    } else {
      return combineLatest([this.csrfToken$, this.accessToken$]).pipe(
        take(1),
        switchMap(([csrfToken, accessToken]: [string, string]) => {
          // On ajoute le token csrf uniquement sur les routes qui ne sont pas en 'GET', 'HEAD', 'OPTIONS'
          const shouldAddCsrfToken = !['GET', 'HEAD', 'OPTIONS'].includes(request.method);
          // On ajoute l'access token uniquement sur les routes non publique
          const shouldAddAccessToken = request.context.get(IS_PUBLIC_API) === false;
          const shouldUpdateRequestHeaders = shouldAddCsrfToken || shouldAddAccessToken;
          let nextRequest: HttpRequest<unknown>;
          if (shouldUpdateRequestHeaders) {
            nextRequest = request.clone({
              setHeaders: {
                ...(shouldAddAccessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
                ...(shouldAddCsrfToken ? { 'x-csrf-token': csrfToken } : {})
              }
            });
          } else {
            nextRequest = request;
          }
          // Envoi de la requête avec les nouvelles entêtes
          return next.handle(nextRequest);
        })
      );
    }
  }
}

export enum UnauthorizedErrorCode {
    USER_NOT_FOUND,
    CREDENTIAL_NOT_MATCHING,
    USER_ALREADY_CONNECTED,
    CREDENTIAL_PASSWORD_NOT_MATCHING,
    NOT_VALID_TOKEN,
    AUTHORIZATION_TYPE_NOT_VALID,
    TOKEN_NOT_FOUND,
    EXPIRED_REFRESH_TOKEN,
    NO_REFRESH_TOKEN,
    NO_CSRF_TOKEN,
    UNAUTHORIZED_ACCESS_TO_ROOM,
    NOT_ROOM_OWNER,
    NOT_MESSAGE_OWNER,
    MESSAGE_NOT_FOUND,
    UNAUTHORIZED_UPDATE_ROOM,
    ROOM_LOCKED,
    USER_BLOCKED,
    UNAUTHORIZED_UPDATE_USER
}

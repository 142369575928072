import { is } from '../../../shared/utils/is-url';

export abstract class AuthenticationApiUrl {
  /**
   * Liste des url d'api
   */
  static readonly csrfToken = 'auth/csrf-token';
  static readonly login = 'auth/login';
  static readonly anonymousRegister = 'auth/anonymous-register';
  static readonly anonymousLogin = 'auth/anonymous-login';
  static readonly refreshToken = 'auth/refresh-token';
  static readonly logout = 'auth/logout-from-all-devices';
  static readonly me = 'auth/me';

  /**
   * Liste des fonctions de détections d'url d'api.
   */
  static readonly isCsrfTokenUrl: (url: string) => boolean = is(AuthenticationApiUrl.csrfToken);
  static readonly isLoginUrl: (url: string) => boolean = is(AuthenticationApiUrl.login);
  static readonly isAnonymousRegisterUrl: (url: string) => boolean = is(AuthenticationApiUrl.anonymousRegister);
  static readonly isAnonymousLoginUrl: (url: string) => boolean = is(AuthenticationApiUrl.anonymousLogin);
  static readonly isRefreshTokenUrl: (url: string) => boolean = is(AuthenticationApiUrl.refreshToken);
  static readonly isLogoutUrl: (url: string) => boolean = is(AuthenticationApiUrl.logout);
  static readonly isMeUrl: (url: string) => boolean = is(AuthenticationApiUrl.me);
}

import { CustomErrorEnum } from '@shared/models/enums/custom-error.enum';

export class CustomError extends Error {
  statusCode: CustomErrorEnum;
  constructor(message: string, statusCode: CustomErrorEnum) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
    this.statusCode = statusCode;
  }
}

import { environment } from '@environments/environment';
import { UrlUtil } from '@proxima/common';

export abstract class ImageHelper {
  static craftImageMessageUrl(imageId: string, roomId: string): string {
    if (imageId && roomId) {
      return UrlUtil.buildUrl(environment.apiEndpoint, 'message/room', roomId, 'image', imageId);
    }
    return '/assets/svg/broken-image.svg';
  }

  static craftAudioMessageUrl(audioId: string, roomId: string): string {
    if (audioId && roomId) {
      return UrlUtil.buildUrl(environment.apiEndpoint, 'message/room', roomId, 'audio', audioId);
    }
    throw new Error('');
  }

  static craftRoomAvatarUrl(avatarId: string, roomId: string): string {
    if (avatarId && roomId) {
      return UrlUtil.buildUrl(environment.apiEndpoint, 'room', roomId, 'image', avatarId);
    }
    return '/assets/svg/broken-image.svg';
  }

  static craftUserAvatarUrl(avatarId: string, userId: string): string {
    if (avatarId && userId) {
      return UrlUtil.buildUrl(environment.apiEndpoint, 'user', userId, 'image', avatarId);
    }
    return '/assets/svg/broken-image.svg';
  }
}

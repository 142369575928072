import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { map, shareReplay } from 'rxjs/operators';
import { AtJwtToken } from '@proxima/common';

type Payload = AtJwtToken & JwtPayload;

@Injectable()
export class JwtTokenService {
  private accessToken$ = new BehaviorSubject<string>('');
  private decodedToken$: Observable<Payload | null>;

  getAccessToken(): Observable<string> {
    return this.accessToken$.asObservable();
  }

  setAccessToken(token: string): void {
    this.accessToken$.next(token);
  }

  getDecodeToken(): Observable<Payload | null> {
    if (!this.decodedToken$) {
      this.decodedToken$ = this.accessToken$.asObservable().pipe(
        map((currentToken: string) => (currentToken ? jwtDecode<Payload>(currentToken) : null)),
        shareReplay(1)
      );
    }
    return this.decodedToken$;
  }

  getUserName(): Observable<string> {
    return this.getDecodeToken().pipe(map((decodedToken: Payload | null) => (decodedToken ? decodedToken.username : '')));
  }

  getExpiryTime(): Observable<number> {
    return this.getDecodeToken().pipe(map((decodedToken: Payload | null) => decodedToken?.exp ?? Number.NaN));
  }

  isTokenExpired(): Observable<boolean> {
    return this.getExpiryTime().pipe(
      map((expiryTime: number) => {
        if (!isNaN(expiryTime)) {
          return 1000 * expiryTime - new Date().getTime() < 5000;
        } else {
          return true;
        }
      })
    );
  }
}

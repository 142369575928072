import { Injectable } from '@angular/core';
import { MessageMapper } from '@business/message/mappers/message.mapper';
import { map, switchMap } from 'rxjs/operators';
import { MessageWithLimitResponseModel } from '@proxima/common';
import { catchError, Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { MessageTreeModel } from '@business/message/data-access/state/message-state';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { FetchRoomMessages } from '@business/message/data-access/state/message.action';
import { MessageDataAccess } from '@business/message/data-access/message.data-access';

@Injectable()
export class FetchMessagesService {
  constructor(private readonly dataAccess: MessageDataAccess, private readonly mapper: MessageMapper, private readonly store: Store) {}

  public fetchNewMessages(currentUserId: string, roomId: string, skip: number, limit = 20): Observable<void> {
    if (roomId) {
      return this.dataAccess.getRoomMessages(roomId, { skip, limit }).pipe(
        map((response: MessageWithLimitResponseModel) =>
          this.mapper.mapMessageResponseModelToMessageTreeModel(currentUserId, response.messages, response.isLastPage)
        ),
        catchError(() =>
          of({
            messages: {},
            idMapping: {},
            order: [],
            isLastPageLoaded: false,
            loadingState: LoadingState.ERROR
          })
        ),
        switchMap((messages: MessageTreeModel) => this.store.dispatch(new FetchRoomMessages(messages, roomId)))
      );
    }
    return of(void 0);
  }
}

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthorizationStatusEnum } from '@shared/models/enums/authorization-status.enum';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import {
  BannedUserInRoom,
  ConnectionStatus,
  CreateRoom,
  DeletedRoom,
  JoinRoomAgreement,
  JoinRoomRefused,
  LogoutSoon,
  NewUserInRoom,
  RoomAvatarUpdated,
  UserDisconnectFromRoom,
  UserLeftRoom
} from '@business/room/communications/data-access/state/room-events.actions';
import {
  BannedFromRoomMessage,
  DisconnectFromRoomMessage,
  getErrorCodeByType,
  LeftRoomMessage,
  NotFoundErrorCode,
  UnauthorizedErrorCode
} from '@proxima/common';
import { HttpException } from '@nestjs/common/exceptions/http.exception';
import { QuitRoom } from '@business/room/communications/data-access/state/room-session.actions';
import { UnreadMessageService } from '@business/message/services/unread-message/unread-message.service';
import { LoadingController } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { ResetAllAvatar } from '@business/avatar/data-access/state/avatar.actions';
import { isHttpWebSocketExceptionModel } from '@shared/typeguard/is-http-exception.type-guard';

@Injectable({
  providedIn: 'root'
})
export class RoomEventsEffectsService {
  private toastInfo: Record<
    AuthorizationStatusEnum,
    { messageFn: (roomName: string, originalError?: HttpException) => string; severity: string }
  > = {
    [AuthorizationStatusEnum.GRANTED]: {
      messageFn: (roomName: string) => `Vous êtes maintenant dans le salon ${roomName}`,
      severity: 'success'
    },
    [AuthorizationStatusEnum.PENDING]: {
      messageFn: (roomName: string) => `Vous êtes maintenant en attente de validation pour le salon ${roomName}`,
      severity: 'info'
    },
    [AuthorizationStatusEnum.ERROR]: {
      messageFn: (roomName: string, originalError?: HttpException) => {
        if (originalError) {
          const isRoomNotFoundError = this.roomNotFoundError(originalError);
          if (isRoomNotFoundError) {
            return `Le salon que vous tenter de joindre n'existe pas`;
          }
          const isRoomLockedError = this.roomLockedError(originalError);
          if (isRoomLockedError) {
            return `Le salon que vous tenter de joindre n'autorise plus de nouveaux membres`;
          }
          const isIncorrectCredentialError = this.incorrectCredentialError(originalError);
          if (isIncorrectCredentialError) {
            return `Les identifiants de connexion ne correspondent pas`;
          }
        }
        const error = `Une erreur est survenu lors de la tentative pour rejoindre le salon ${roomName}`;
        return error;
      },
      severity: 'error'
    },
    [AuthorizationStatusEnum.REFUSED]: {
      messageFn: (roomName: string) => `Vous n'avez pas pu rejoindre le salon ${roomName}`,
      severity: 'error'
    },
    [AuthorizationStatusEnum.UNKNOWN]: { messageFn: () => `Impossible`, severity: 'error' }
  };

  constructor(
    private readonly toastService: MessageService,
    private readonly loadingCtrl: LoadingController,
    private readonly unreadMessageService: UnreadMessageService,
    private readonly actions$: Actions,
    private readonly store: Store
  ) {
    this.actions$
      .pipe(
        ofActionDispatched(UserLeftRoom),
        tap(({ userLeftMessage, currentUserId }) => this.displayUserLeftMessage(userLeftMessage, currentUserId))
      )
      .subscribe();
    this.actions$
      .pipe(ofActionDispatched(UserDisconnectFromRoom))
      .pipe(
        tap(({ userDisconnectedMessage, currentUserId }) => this.displayUserDisconnectedFromRoom(userDisconnectedMessage, currentUserId))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(BannedUserInRoom),
        tap(({ bannedUserMessage, currentUserId }) => this.displayUserBannedInRoom(bannedUserMessage, currentUserId))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(NewUserInRoom),
        tap(({ newUserName }) => this.displayNewUserMessage(newUserName))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(JoinRoomAgreement),
        tap(({ roomName, authorizationStatus, originalError }) => this.displayJoinMessage(roomName, authorizationStatus, originalError))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(ConnectionStatus),
        tap(({ isConnected }) => this.displayConnectionStatus(isConnected))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(DeletedRoom),
        tap(({ roomName }) => this.displayDeletedRoomMessage(roomName))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(CreateRoom),
        tap(({ roomName }) => this.displayCreateRoomMessage(roomName))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(JoinRoomRefused),
        tap(({ roomName }) => this.displayJoinRoomRefusedMessage(roomName))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(QuitRoom),
        tap(() => {
          this.unreadMessageService.resetUnreadMessageCount();
        })
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(LogoutSoon),
        switchMap(() => this.displayLogoutSoonMessage()),
        switchMap((loader) => loader.present()),
        switchMap(() => this.store.dispatch(new ResetAllAvatar()))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofActionDispatched(RoomAvatarUpdated),
        tap(() => this.displayRoomAvatarUpdated())
      )
      .subscribe();
  }

  private roomNotFoundError(originalError: HttpException): boolean {
    if (isHttpWebSocketExceptionModel(originalError)) {
      return getErrorCodeByType(originalError.statusCode, NotFoundErrorCode.ROOM_NOT_FOUND, NotFoundErrorCode, originalError.errorCode);
    }
    return false;
  }

  private roomLockedError(originalError: HttpException): boolean {
    if (isHttpWebSocketExceptionModel(originalError)) {
      return getErrorCodeByType(
        originalError.statusCode,
        UnauthorizedErrorCode.ROOM_LOCKED,
        UnauthorizedErrorCode,
        originalError.errorCode
      );
    }
    return false;
  }

  private incorrectCredentialError(originalError: HttpException): boolean {
    if (isHttpWebSocketExceptionModel(originalError)) {
      const isCred = getErrorCodeByType(
        originalError.statusCode,
        UnauthorizedErrorCode.CREDENTIAL_NOT_MATCHING,
        UnauthorizedErrorCode,
        originalError.errorCode
      );
      const isCredAndPwd = getErrorCodeByType(
        originalError.statusCode,
        UnauthorizedErrorCode.CREDENTIAL_PASSWORD_NOT_MATCHING,
        UnauthorizedErrorCode,
        originalError.errorCode
      );
      return isCred || isCredAndPwd;
    }
    return false;
  }

  private displayUserLeftMessage(userLeftMessage: LeftRoomMessage, currentUserId: string): void {
    const detail =
      currentUserId === userLeftMessage.userId
        ? `Vous avez quitté le salon`
        : `L'utilisateur ${userLeftMessage.userName} vient de quitter le salon`;
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail,
      severity: 'warn'
    });
  }

  private displayUserDisconnectedFromRoom(disconnectedUserMessage: DisconnectFromRoomMessage, currentUserId: string): void {
    const detail =
      currentUserId === disconnectedUserMessage.userId
        ? `Vous vous êtes déconnecté du salon`
        : `L'utilisateur ${disconnectedUserMessage.userName} vient de se deconnecter du salon`;
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail,
      severity: 'error'
    });
  }

  private displayUserBannedInRoom(bannedUserMessage: BannedFromRoomMessage, currentUserId: string): void {
    const detail =
      currentUserId === bannedUserMessage.userId
        ? `Vous avez été expulsé du salon`
        : `L'utilisateur ${bannedUserMessage.userName} vient d'être expulsé du salon`;
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail,
      severity: 'error'
    });
  }

  private displayNewUserMessage(newUserName: string): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail: `L'utilisateur ${newUserName} vient de rejoindre le salon`,
      severity: 'success'
    });
  }

  private displayJoinMessage(roomName: string, authorizationStatus: AuthorizationStatusEnum, originalError?: HttpException): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail: this.toastInfo[authorizationStatus].messageFn(roomName, originalError),
      severity: this.toastInfo[authorizationStatus].severity
    });
  }

  private displayConnectionStatus(isConnected: boolean): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-wifi',
      detail: isConnected ? 'Vous êtes de nouveau connecté' : 'Le serveur ne répond plus. Reconnexion...',
      severity: isConnected ? 'success' : 'error'
    });
  }

  private displayDeletedRoomMessage(roomName: string): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail: `Le salon ${roomName} a été supprimé`,
      severity: 'success'
    });
  }

  private displayCreateRoomMessage(roomName: string): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-wifi',
      detail: `Le salon ${roomName} a été créé`,
      severity: 'success'
    });
  }

  private displayJoinRoomRefusedMessage(roomName: string): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail: `Le propriétaire du salon ${roomName} a refusé votre demande`,
      severity: 'error'
    });
  }

  private displayLogoutSoonMessage(): Observable<HTMLIonLoadingElement> {
    return from(
      this.loadingCtrl.create({
        message: 'Vous allez automatiquement être deconnecté dans 5 secondes!',
        duration: 5000
      })
    );
  }

  private displayRoomAvatarUpdated(): void {
    this.toastService.add({
      key: 'roomEvents',
      icon: 'pi-comments',
      detail: `Le propriétaire du salon a modifié l'avatar du salon`,
      severity: 'success'
    });
  }
}

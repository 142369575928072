import { Injectable } from '@angular/core';
import { AuthDataAccess } from '../communications/data-access/auth.data-access';
import { JwtTokenService } from './jwt-token.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, catchError, distinctUntilChanged, interval, NEVER, Observable, of, withLatestFrom } from 'rxjs';
import { ClientSenderSocketService } from '../../../services/websocket/client-sender-socket/client-sender-socket.service';

@Injectable()
export class KeepAliveService {
  // TODO à déplacer
  private readonly accessTokenExpiration = 300000; //5min
  private readonly refreshTokenInterval = this.accessTokenExpiration - 30000; // 5min - 30s
  private isKeepAliveEnabled$ = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly dataAccess: AuthDataAccess,
    private readonly jwtTokenService: JwtTokenService,
    private readonly clientSender: ClientSenderSocketService
  ) {
    this.isKeepAliveEnabled$
      .pipe(
        distinctUntilChanged(),
        switchMap((isEnabled) => (isEnabled ? this.keepAlive() : NEVER))
      )
      .subscribe();
  }

  public shouldListenKeepAlive(shouldListen: boolean) {
    this.isKeepAliveEnabled$.next(shouldListen);
  }

  private keepAlive(): Observable<void> {
    return this.jwtTokenService.getAccessToken().pipe(
      filter((accessToken) => !!accessToken),
      switchMap(() =>
        interval(this.refreshTokenInterval).pipe(
          withLatestFrom(this.clientSender.getSocketConnectionId()),
          switchMap(([, socketConnectionId]: [number, string]) => this.dataAccess.refreshToken({ socketConnectionId })),
          map(() => void 0),
          catchError(() => of(void 0))
        )
      )
    );
  }
}

import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddConnectedUserToRoom,
  AddMembersToRoom,
  AddNewSession,
  QuitRoom,
  RemoveConnectedUserToRoom,
  RemoveUserToRoom,
  ResetConnectedUsersToRoom,
  SetFocusedUserIdOnMap,
  SetFocusedUserStatusOnMap,
  SetPendingUserGranted,
  UpdateMemberAvatar,
  UpdateMemberSpeakingStatus,
  UpdateRoomAvatar,
  UpdateRoomDescription,
  UpdateRoomLockStatus
} from './room-session.actions';
import { RoomUser } from '@shared/models/session.models';
import { MediaModel } from '@proxima/common';
import { AuthorizationStatusEnum } from '@shared/models/enums/authorization-status.enum';
import { UserState } from '@business/user/communications/data-access/state/user.state';
import { FocusedUserOnMapModel } from '@shared/models/focused-user-on-map.model';
import { FocusedUserOnMapStatusEnum } from '@shared/models/enums/focused-user-on-map-status.enum';
import { StateReset } from 'ngxs-reset-plugin';

export interface RoomSessionModel {
  roomId: string;
  roomName: string;
  roomOwnerId: string;
  avatar?: MediaModel;
  description?: string;
  members: Record<string, RoomUser>;
  isOwner: boolean;
  isLocked: boolean;
  authorizationStatus: AuthorizationStatusEnum;
  createdAt: Date;
  focusedUserOnMap: FocusedUserOnMapModel;
}

const ROOM_SESSION_STATE_TOKEN = new StateToken<RoomSessionModel>('roomSession');
const defaultRoomSession: RoomSessionModel = {
  roomId: '',
  roomName: '',
  roomOwnerId: '',
  members: {},
  isOwner: false,
  isLocked: false,
  authorizationStatus: AuthorizationStatusEnum.UNKNOWN,
  createdAt: new Date(),
  focusedUserOnMap: {
    userId: '',
    status: FocusedUserOnMapStatusEnum.DETACHED
  }
};

@State({
  name: ROOM_SESSION_STATE_TOKEN,
  defaults: defaultRoomSession
})
@Injectable()
export class RoomSessionState implements NgxsOnInit {
  @Selector()
  static getRoomSession(state: RoomSessionModel): RoomSessionModel {
    return state;
  }

  @Selector()
  static getRoomName(state: RoomSessionModel): string {
    return state.roomName;
  }

  @Selector()
  static getUsersList(state: RoomSessionModel): Record<string, RoomUser> {
    return state.members;
  }

  @Selector()
  static getRoomId(state: RoomSessionModel): string {
    return state.roomId;
  }

  @Selector()
  static getAvatarId(state: RoomSessionModel): string {
    return state.avatar?.id ?? '';
  }

  @Selector()
  static getAuthorizationStatus(state: RoomSessionModel): AuthorizationStatusEnum {
    return state.authorizationStatus;
  }

  @Selector()
  static isOwner(state: RoomSessionModel): boolean {
    return state.isOwner;
  }

  @Selector()
  static getMembers(state: RoomSessionModel): Record<string, RoomUser> {
    return state.members;
  }

  @Selector()
  static getSelectedFocusedUserOnMap(state: RoomSessionModel): string {
    return state.focusedUserOnMap.userId;
  }

  @Selector()
  static getSelectedFocusedStatusOnMap(state: RoomSessionModel): FocusedUserOnMapStatusEnum {
    return state.focusedUserOnMap.status;
  }

  @Selector([RoomSessionState.getMembers])
  static getConnectedUsers(members: Record<string, RoomUser>): Record<string, RoomUser> {
    return Object.values(members).reduce(
      (acc, member) => {
        if (member.connectedToRoom) {
          acc[member.id] = member;
        }
        return acc;
      },
      {} as Record<string, RoomUser>
    );
  }

  @Selector([RoomSessionState.getConnectedUsers, UserState.getUserId])
  static getConnectedUsersWithoutCurrentUser(connectedUsers: Record<string, RoomUser>, currentUserId: string): Record<string, RoomUser> {
    return Object.entries(connectedUsers).reduce(
      (acc, [key, value]) => {
        if (key !== currentUserId) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, RoomUser>
    );
  }

  @Selector([RoomSessionState.getConnectedUsers])
  static getSpeakingStatusForAllConnectedUsers(connectedUsers: Record<string, RoomUser>): Record<string, boolean> {
    return Object.entries(connectedUsers).reduce(
      (acc, [key, value]) => {
        acc[key] = value.isSpeaking;
        return acc;
      },
      {} as Record<string, boolean>
    );
  }

  @Selector([RoomSessionState.getConnectedUsers])
  static getCurrentSpeaker(connectedUsers: Record<string, RoomUser>): RoomUser | null {
    return Object.values(connectedUsers).find((user) => user.isSpeaking) || null;
  }

  static getConnectedUserNameById(userId: string): (connectedUsers: Record<string, RoomUser>) => string | null {
    return createSelector(
      [RoomSessionState.getConnectedUsers],
      (connectedUsers: Record<string, RoomUser>) => connectedUsers[userId]?.name || null
    );
  }

  @Selector([RoomSessionState.getConnectedUsersWithoutCurrentUser])
  static isSomeoneSpeaking(connectedUsersWithoutMe: Record<string, RoomUser>): boolean {
    return Object.values(connectedUsersWithoutMe).some((member) => member.isSpeaking);
  }

  @Action(AddNewSession)
  addNewSession({ setState }: StateContext<RoomSessionModel>, { sessionInfo }: AddNewSession) {
    setState({
      roomName: sessionInfo.roomName,
      roomOwnerId: sessionInfo.roomOwnerId,
      members: sessionInfo.existingUsers.reduce(
        (acc, member) => {
          acc[member.id] = member;
          // Si on dispatch cette action c'est qu'on a rejoint le salon donc on change le statut de
          // connectedToRoom à true pour notre compte
          if (member.id === sessionInfo.userId) {
            acc[member.id].connectedToRoom = true;
          }
          return acc;
        },
        {} as Record<string, RoomUser>
      ),
      isOwner: sessionInfo.isOwner,
      roomId: sessionInfo.roomId,
      isLocked: sessionInfo.isLocked,
      authorizationStatus: sessionInfo.authorizationStatus,
      createdAt: new Date(sessionInfo.createdAt),
      focusedUserOnMap: {
        userId: sessionInfo.userId,
        status: FocusedUserOnMapStatusEnum.ATTACHED
      },
      ...(sessionInfo.description ? { description: sessionInfo.description } : {}),
      ...(sessionInfo.avatar ? { avatar: sessionInfo.avatar } : {})
    });
  }

  @Action(SetFocusedUserIdOnMap)
  setFocusedUserIdOnMap({ getState, patchState }: StateContext<RoomSessionModel>, { userId, status }: SetFocusedUserIdOnMap) {
    const focusedUser = getState().focusedUserOnMap;
    patchState({
      focusedUserOnMap: {
        userId,
        status: status ?? focusedUser.status
      }
    });
  }

  @Action(SetFocusedUserStatusOnMap)
  setFocusedUserStatusOnMap({ getState, patchState }: StateContext<RoomSessionModel>, { status }: SetFocusedUserStatusOnMap) {
    const { userId } = getState().focusedUserOnMap;
    patchState({
      focusedUserOnMap: {
        status,
        userId
      }
    });
  }

  @Action(AddMembersToRoom)
  addMembersToRoom({ patchState }: StateContext<RoomSessionModel>, { members }: AddMembersToRoom) {
    patchState({
      members: members.reduce(
        (acc, member) => {
          acc[member.id] = member;
          return acc;
        },
        {} as Record<string, RoomUser>
      )
    });
  }

  @Action(UpdateRoomDescription)
  updateRoomDescription({ getState, patchState }: StateContext<RoomSessionModel>, { description }: UpdateRoomDescription) {
    const currentDescription = getState().description;
    if (currentDescription != description) {
      patchState({
        description
      });
    }
  }

  @Action(RemoveUserToRoom)
  removeUserToRoom({ patchState, getState }: StateContext<RoomSessionModel>, { userId }: RemoveUserToRoom) {
    const { members } = getState();
    if (Object.keys(members).includes(userId)) {
      patchState({
        members: Object.values(members).reduce(
          (acc, member) => {
            if (member.id !== userId) {
              acc[member.id] = member;
            }
            return acc;
          },
          {} as Record<string, RoomUser>
        )
      });
    }
  }

  @Action(SetPendingUserGranted)
  setPendingUserGranted({ patchState }: StateContext<RoomSessionModel>) {
    patchState({ authorizationStatus: AuthorizationStatusEnum.GRANTED });
  }

  @Action(AddConnectedUserToRoom)
  addConnectedUserToRoom({ patchState, getState }: StateContext<RoomSessionModel>, { user }: AddConnectedUserToRoom) {
    const { members } = getState();
    patchState({
      members: { ...members, [user.id]: { ...user, connectedToRoom: true } }
    });
  }

  @Action(RemoveConnectedUserToRoom)
  removeConnectedUserToRoom({ patchState, getState }: StateContext<RoomSessionModel>, { userId }: RemoveConnectedUserToRoom) {
    const { members } = getState();
    patchState({
      members: Object.values(members).reduce(
        (acc, member) => {
          acc[member.id] = { ...member, connectedToRoom: member.id === userId ? false : member.connectedToRoom };
          return acc;
        },
        {} as Record<string, RoomUser>
      )
    });
  }

  @Action(ResetConnectedUsersToRoom)
  resetConnectedUsersToRoom({ getState, patchState }: StateContext<RoomSessionModel>) {
    const { members } = getState();
    patchState({
      members: Object.values(members).reduce(
        (acc, member) => {
          acc[member.id] = { ...member, connectedToRoom: false };
          return acc;
        },
        {} as Record<string, RoomUser>
      )
    });
  }

  @Action(UpdateRoomLockStatus)
  updateRoomLockStatus({ patchState }: StateContext<RoomSessionModel>, { shouldLock }: UpdateRoomLockStatus) {
    patchState({ isLocked: shouldLock });
  }

  @Action(UpdateRoomAvatar)
  updateRoomAvatar({ patchState }: StateContext<RoomSessionModel>, { avatar }: UpdateRoomAvatar) {
    patchState({ avatar });
  }

  @Action(UpdateMemberAvatar)
  updateMemberAvatar({ patchState, getState }: StateContext<RoomSessionModel>, { newAvatar, userId }: UpdateMemberAvatar) {
    const { members } = getState();
    patchState({
      members: Object.values(members).reduce(
        (acc, member) => {
          acc[member.id] = { ...member, avatar: member.id === userId ? newAvatar : member.avatar };
          return acc;
        },
        {} as Record<string, RoomUser>
      )
    });
  }

  @Action(UpdateMemberSpeakingStatus)
  updateMemberSpeakingStatus({ getState, patchState }: StateContext<RoomSessionModel>, { isSpeaking, userId }: UpdateMemberSpeakingStatus) {
    const { members } = getState();
    patchState({
      members: Object.values(members).reduce(
        (acc, member) => {
          acc[member.id] = { ...member, isSpeaking: member.id === userId ? isSpeaking : member.isSpeaking };
          return acc;
        },
        {} as Record<string, RoomUser>
      )
    });
  }

  @Action(QuitRoom)
  quitRoom({ dispatch }: StateContext<RoomSessionModel>) {
    dispatch(new StateReset(RoomSessionState));
  }

  ngxsOnInit({ dispatch }: StateContext<RoomSessionModel>) {
    dispatch(new StateReset(RoomSessionState));
  }
}

import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserJobEnum, UserResponse, UserRole } from '@proxima/common';
import { ResetUser, SetUser, SetUserLoadingStatus } from '@business/user/communications/data-access/state/user.actions';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { StateReset } from 'ngxs-reset-plugin';

export interface UserStateModel {
  state: LoadingState;
  user: UserResponse;
}

const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');
const defaultUser: UserStateModel = {
  state: LoadingState.NOT_LOADED,
  user: {
    id: '',
    role: UserRole.ANONYMOUS,
    job: UserJobEnum.APS,
    color: '',
    name: '',
    online: false,
    connectedRoom: ''
  }
};

@State({
  name: USER_STATE_TOKEN,
  defaults: defaultUser
})
@Injectable()
export class UserState implements NgxsOnInit {
  @Selector()
  static getUser(state: UserStateModel): UserResponse {
    return state.user;
  }

  @Selector([UserState.getUser])
  static getUserId(user: UserResponse): string {
    return user?.id || '';
  }

  @Selector()
  static getLoadingState(state: UserStateModel): LoadingState {
    return state.state;
  }

  @Action(SetUser)
  setUser({ patchState }: StateContext<UserStateModel>, { user }: SetUser): void {
    patchState({ user });
  }

  @Action(ResetUser)
  resetUser({ dispatch }: StateContext<UserStateModel>): void {
    dispatch(new StateReset(UserState));
  }

  @Action(SetUserLoadingStatus)
  setUserLoadingStatus({ patchState }: StateContext<UserStateModel>, { loadingState }: SetUserLoadingStatus) {
    patchState({ state: loadingState });
  }

  ngxsOnInit({ dispatch }: StateContext<UserStateModel>) {
    dispatch(new StateReset(UserState));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AudioMessageResponseModel,
  FetchMessagesDtoType,
  FormattedResponse,
  ImageMessageResponseModel,
  MessageWithLimitResponseModel,
  UrlUtil
} from '@proxima/common';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { MessageApiUrl } from '../constants/api-url.constants';
import { MessageMapper } from '../../mappers/message.mapper';
import { isDefined } from '@shared/typeguard/is-defined.type-guard';

@Injectable()
export class MessageClient {
  private headers = {
    'Content-Type': 'application/json'
  };

  constructor(
    private readonly http: HttpClient,
    private readonly messageMapper: MessageMapper
  ) {}

  public getRoomMessages(roomId: string, query: FetchMessagesDtoType): Observable<MessageWithLimitResponseModel> {
    let queryParams = new HttpParams();
    if (isDefined(query.limit)) {
      queryParams = queryParams.append('limit', query.limit);
    }
    if (isDefined(query.skip)) {
      queryParams = queryParams.append('skip', query.skip);
    }
    const craftedUrlWithId = MessageApiUrl.getRoomMessages.replace('%id', roomId);
    return this.http
      .get<FormattedResponse<MessageWithLimitResponseModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        params: queryParams,
        withCredentials: true
      })
      .pipe(map((res) => this.messageMapper.mapFormattedMessageResponse(res)));
  }

  public uploadImageMessage(roomId: string, avatar: File, legend?: string): Observable<ImageMessageResponseModel> {
    const craftedUrlWithId = MessageApiUrl.uploadImageMessage.replace('%id', roomId);
    const formData: FormData = new FormData();

    formData.append('image', avatar);
    if (legend) {
      formData.append('legend', legend);
    }

    return this.http
      .post<FormattedResponse<ImageMessageResponseModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), formData, {
        withCredentials: true
      })
      .pipe(map((res) => this.messageMapper.mapFormattedMessageResponse(res)));
  }

  public uploadAudioMessage(roomId: string, audio: File): Observable<AudioMessageResponseModel> {
    const craftedUrlWithId = MessageApiUrl.uploadAudioMessage.replace('%id', roomId);
    const formData: FormData = new FormData();

    formData.append('audio', audio);

    return this.http
      .post<FormattedResponse<AudioMessageResponseModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), formData, {
        withCredentials: true
      })
      .pipe(map((res) => this.messageMapper.mapFormattedMessageResponse(res)));
  }
}

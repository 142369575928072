import { concatMap, filter, map, Observable, of, OperatorFunction } from 'rxjs';
import { NgZone } from '@angular/core';

export type Nullable<T> = T extends null | undefined ? T : never;

export abstract class RxOperatorUtil {
  static isNonNullable<T>(data: T): data is NonNullable<T> {
    return data !== null && data !== undefined;
  }

  static isNullable<T>(data: T): data is Nullable<T> {
    return data === null || data === undefined;
  }
  static filterNullOrUndefined<T>(): (source: Observable<T>) => Observable<NonNullable<T>> {
    return (source$: Observable<T>) => source$.pipe(filter(RxOperatorUtil.isNonNullable));
  }

  static throwErrorIfDefined<T>(error: Error): (source: Observable<T>) => Observable<Nullable<T>> {
    return (source$: Observable<T>) =>
      source$.pipe(
        map((data: T) => {
          if (RxOperatorUtil.isNullable(data)) {
            return data;
          } else {
            throw error;
          }
        })
      );
  }

  static throwErrorIfFalsy<T>(error: Error): (source: Observable<T>) => Observable<T> {
    return (source$: Observable<T>) =>
      source$.pipe(
        map((data: T) => {
          if (data) {
            return data;
          } else {
            throw error;
          }
        })
      );
  }

  static throwErrorIfNullOrUndefined<T>(error: Error): (source: Observable<T>) => Observable<NonNullable<T>> {
    return (source$: Observable<T>) =>
      source$.pipe(
        map((data: T) => {
          if (RxOperatorUtil.isNonNullable(data)) {
            return data;
          } else {
            throw error;
          }
        })
      );
  }

  static tapOnce<T>(tapFn: (t: T) => void, tapIndex = 0): OperatorFunction<T, T> {
    return (source$) =>
      source$.pipe(
        concatMap((value, index) => {
          if (index === tapIndex) {
            tapFn(value);
          }
          return of(value);
        })
      );
  }

  /**
   * Permet de wrapper un observable pour qu'Angular puisse détecter des changements et puisse mettre à jour la vue
   * quand c'est nécessaire.
   *
   * @param zone
   */
  static runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
    return (source) => {
      return new Observable((observer) => {
        const onNext = (value: T) => zone.run(() => observer.next(value));
        const onError = (e: any) => zone.run(() => observer.error(e));
        const onComplete = () => zone.run(() => observer.complete());
        return source.subscribe({ next: onNext, error: onError, complete: onComplete });
      });
    };
  }
}

import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, of, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetAvatar, SetAvatarLoadingStatus } from '@business/avatar/data-access/state/avatar.actions';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { ImageHelper } from '../../../presentation/pages/dashboard/chat/core/helpers/craft-image';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MediaBlobResponse, MediaBlobResponseStateEnum } from '@shared/models/media-blob-response';
import { MediaResolverService } from '../../../services/image-resolver/media-resolver.service';
import { AvatarState } from '@business/avatar/data-access/state/avatar.state';
import { RxOperatorUtil } from '@proxima/common';
import { AvatarEntityModel } from '@business/avatar/data-access/state/avatar.model';
import { Platform } from '@ionic/angular';

@Injectable()
export class AvatarDataAccess {
  constructor(
    private readonly store: Store,
    private readonly mediaResolver: MediaResolverService,
    private readonly platform: Platform
  ) {}

  public get(avatarId: string, roomOrUserId: string, isRoomAvatar: boolean): Observable<AvatarEntityModel> {
    return this.refreshIfNeeded(avatarId, roomOrUserId, isRoomAvatar).pipe(
      switchMap(() => this.store.select(AvatarState.getAvatarState(avatarId))),
      RxOperatorUtil.filterNullOrUndefined(),
      finalize(() => this.cancelLoadingIfNeeded(avatarId))
    );
  }

  private refresh(avatarId: string, roomOrUserId: string, isRoomAvatar: boolean): Observable<void> {
    return this.store.dispatch(new SetAvatarLoadingStatus(avatarId, LoadingState.LOADING)).pipe(
      switchMap(() => this.mediaResolver.getFileIfAlreadySavedOnDevice(avatarId, roomOrUserId)),
      switchMap((fileOnDevice: string | null) => {
        if (!fileOnDevice) {
          return this.mediaResolver
            .getMediaBlobResponse(
              isRoomAvatar ? ImageHelper.craftRoomAvatarUrl(avatarId, roomOrUserId) : ImageHelper.craftUserAvatarUrl(avatarId, roomOrUserId)
            )
            .pipe(
              tap((thumbnailBlobResponse: MediaBlobResponse) => {
                if (thumbnailBlobResponse.state === MediaBlobResponseStateEnum.ERROR) {
                  throw new Error();
                }
              }),
              filter((thumbnailBlobResponse: MediaBlobResponse) => thumbnailBlobResponse.state === MediaBlobResponseStateEnum.DONE),
              switchMap((thumbnailBlobResponse: MediaBlobResponse) =>
                this.mediaResolver.createImageFromBlob(thumbnailBlobResponse.content)
              ),
              switchMap((avatarUrl: string) => {
                if (this.platform.is('mobile') && !!avatarUrl) {
                  return this.mediaResolver.createFileOnDevice(avatarId, roomOrUserId, avatarUrl).pipe(map(() => avatarUrl));
                } else {
                  return of(avatarUrl);
                }
              }),
              switchMap((avatarUrl: string) =>
                this.store.dispatch([new SetAvatarLoadingStatus(avatarId, LoadingState.LOADED), new SetAvatar(avatarUrl, avatarId)])
              )
            );
        } else {
          return this.store.dispatch([new SetAvatarLoadingStatus(avatarId, LoadingState.LOADED), new SetAvatar(fileOnDevice, avatarId)]);
        }
      }),
      catchError(() =>
        this.store.dispatch([
          new SetAvatarLoadingStatus(avatarId, LoadingState.ERROR),
          new SetAvatar('/assets/svg/broken-image.svg', avatarId)
        ])
      )
    );
  }

  private refreshIfNeeded(avatarId: string, roomOrUserId: string, isRoomAvatar: boolean): Observable<void> {
    return this.shouldRefresh(avatarId).pipe(
      switchMap((shouldRefresh) => (shouldRefresh ? this.refresh(avatarId, roomOrUserId, isRoomAvatar) : of(null))),
      switchMap(() => this.store.select(AvatarState.getLoadingState(avatarId))),
      filter((loadingState) => loadingState === LoadingState.LOADED || loadingState === LoadingState.ERROR),
      map(() => void 0)
    );
  }

  private cancelLoadingIfNeeded(avatarId: string): void {
    this.store
      .select(AvatarState.getLoadingState(avatarId))
      .pipe(
        map((loadingState) => loadingState === LoadingState.LOADING),
        take(1)
      )
      .subscribe((wasLoading) => {
        if (wasLoading) {
          this.store.dispatch([new SetAvatarLoadingStatus(avatarId, LoadingState.NOT_LOADED), new SetAvatar('', avatarId)]);
        }
      });
  }

  private shouldRefresh(avatarId: string): Observable<boolean> {
    return this.store.select(AvatarState.getLoadingState(avatarId)).pipe(
      map((loadingState) => !loadingState || loadingState === LoadingState.NOT_LOADED),
      take(1)
    );
  }
}

import { NgModule } from '@angular/core';
import { UserMapper } from '@business/user/communications/mappers/user.mapper';
import { UserClient } from '@business/user/communications/services/user.client';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';
import { NgxsModule } from '@ngxs/store';
import { UserState } from '@business/user/communications/data-access/state/user.state';

@NgModule({
  imports: [NgxsModule.forFeature([UserState])],
  providers: [UserMapper, UserClient, UserDataAccess]
})
export class UserModule {}

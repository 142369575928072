import { LoadingState } from '@shared/models/enums/loading-state.enum';

export class SetAvatar {
    public static type = '[AVATAR] Set avatar';

    constructor(public readonly avatarUrl: string, public readonly avatarId: string) {
    }
}

export class SetAvatarLoadingStatus {
  public static type = '[AVATAR] Set avatar loading status';

  constructor(
    public readonly avatarId: string,
    public readonly loadingState: LoadingState
  ) {}
}

export class ResetAllAvatar {
    public static type = '[AVATAR] Reset all avatar';
}

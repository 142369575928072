import { LatLngPosition } from '@proxima/common';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export abstract class Util {
  static createNearbyLocation(position: LatLngPosition, radiusInMeter: number): LatLngPosition {
    // Convert radius from meters to degrees
    const radiusInDegrees: number = radiusInMeter / 111000;

    const u: number = Math.random();
    const v: number = Math.random();
    const w: number = radiusInDegrees * Math.sqrt(u);
    const t: number = 2 * Math.PI * v;
    const x: number = w * Math.cos(t);
    const y: number = w * Math.sin(t);

    // Adjust the x-coordinate for the shrinking of the east-west distances
    const newX: number = x / Math.cos(position.lat * (Math.PI / 180));

    const foundLongitude: number = newX + position.lng;
    const foundLatitude: number = y + position.lat;
    return {
      lng: foundLongitude,
      lat: foundLatitude
    };
  }

  static isUnknownObject(object: unknown): object is { [key in PropertyKey]: unknown } {
    return object !== null && typeof object === 'object';
  }

  static isEnumValue<T extends { [key: number]: string }>(value: unknown, enumEntity: T): value is T[keyof T] & string {
    const isString = typeof value === 'string';
    const isIncludedInEnum = Object.values(enumEntity).includes(value as string);
    return isString && isIncludedInEnum;
  }

  static getEntries<T extends object>(object: T): [keyof T, T[keyof T]][] {
    return Object.entries(object) as [keyof T, T[keyof T]][];
  }

  static recursiveMerge<T extends NonNullable<Record<string | number, any>>>(source: T, objToMerge: RecursivePartial<T>): T {
    if (!!objToMerge && !!source) {
      for (const [key, value] of this.getEntries(objToMerge)) {
        if (this.isUnknownObject(value)) {
          this.recursiveMerge(source[key], value as T[keyof T]);
        } else {
          source[key] = value as T[keyof T];
        }
      }
    }
    return source;
  }
}

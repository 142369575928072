import { Injectable } from '@angular/core';
import { RoomClient } from '../services/room.client';
import {
  AddRoomDtoType,
  ConnectionRequestResponseModel,
  GetRoomByJoinIdDtoType,
  JoinRoomDtoType,
  MediaModel,
  PendingUserAgreementDtoType,
  PendingUserByRoomMessage,
  RoomResponse,
  UpdateDescriptionDtoType,
  UpdateRoomPasswordDtoType
} from '@proxima/common';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RoomDataAccess {
  constructor(private readonly client: RoomClient) {}

  public getRoomByJoinIdAndPassword(body: GetRoomByJoinIdDtoType): Observable<RoomResponse | null> {
    return this.client.getRoomByJoinIdAndPassword(body).pipe(
      catchError(() =>
        // TODO gérer les cas d'erreurs
        of(null)
      )
    );
  }

  public joinRoom(body: JoinRoomDtoType): Observable<boolean> {
    return this.client.joinRoom(body).pipe(
      catchError(() =>
        // TODO gérer les cas d'erreurs
        of(false)
      )
    );
  }

  /**
   * Récupère la liste des salons dans lequel je suis membre.
   */
  public getAuthorizedRoom(): Observable<RoomResponse[]> {
    return this.client.getAuthorizedRoom().pipe(catchError(() => of([])));
  }

  /**
   * Récupère la liste des salons dans lequel je suis en attente de validation.
   */
  public getRoomsByPendingMember(): Observable<RoomResponse[]> {
    return this.client.getRoomsByPendingMember().pipe(catchError(() => of([])));
  }

  public getOwnedRooms(): Observable<RoomResponse[]> {
    return this.client.getOwnedRooms().pipe(catchError(() => of([])));
  }

  public getAllPendingUserByOwnedRoom(): Observable<PendingUserByRoomMessage[]> {
    return this.client.getAllPendingUserByOwnedRoom().pipe(catchError(() => of([])));
  }

  public pendingUserAgreement(body: PendingUserAgreementDtoType, roomId: string): Observable<boolean> {
    return this.client.pendingUserAgreement(body, roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public leaveRoom(roomId: string): Observable<boolean> {
    return this.client.leaveRoom(roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public banUser(userId: string, roomId: string): Observable<boolean> {
    return this.client.banUser(userId, roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteRoom(roomId: string): Observable<boolean> {
    return this.client.deleteRoom(roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getRoomJoinIdFromOwner(roomId: string): Observable<string> {
    return this.client.getRoomJoinIdFromOwner(roomId).pipe(catchError(() => of('')));
  }

  public getConnectionRequestFromOwner(roomId: string): Observable<ConnectionRequestResponseModel> {
    return this.client.getConnectionRequestFromOwner(roomId);
  }

  public getRoomFromConnectionRequest(linkId: string): Observable<RoomResponse | null> {
    return this.client.getRoomFromConnectionRequest(linkId);
  }

  public updateRoomPassword(roomId: string, body: UpdateRoomPasswordDtoType): Observable<void> {
    // On veut pouvoir traiter l'erreur par l'appelant
    return this.client.updateRoomPassword(roomId, body);
  }

  public updateRoomAvatar(roomId: string, avatar: File): Observable<MediaModel> {
    return this.client.updateRoomAvatar(roomId, avatar);
  }

  public updateDescription(roomId: string, body: UpdateDescriptionDtoType): Observable<void> {
    // On veut pouvoir traiter l'erreur par l'appelant
    return this.client.updateDescription(roomId, body);
  }

  public createRoom(body: AddRoomDtoType, avatar?: File): Observable<void> {
    // On veut pouvoir traiter l'erreur par l'appelant
    return this.client.createRoom(body, avatar);
  }

  public lockRoom(roomId: string): Observable<boolean> {
    return this.client.lockRoom(roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public unlockRoom(roomId: string): Observable<boolean> {
    return this.client.unlockRoom(roomId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}

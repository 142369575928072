import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { catchError, defer, Observable, throwError } from 'rxjs';
import { CapacitorHttp, HttpHeaders as CapacitorHttpHeaders, HttpOptions, HttpParams as CapacitorHttpParams } from '@capacitor/core';
import { map, switchMap } from 'rxjs/operators';

interface CommonResponse<T = any> {
  readonly body: T | null;
  readonly headers: HttpHeaders;
  readonly status: number;
  readonly url: string;
}

@Injectable()
export class CapacitorHttpInterceptor implements HttpInterceptor {
  constructor(private platform: Platform) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.platform.is('mobile')) {
      return this.interceptNativeRequest(req);
    }
    return this.interceptWebRequest(req, next);
  }

  /**
   * Intercept Angular http client and use the Capacitor Http plugin.
   * It is useful to bypass the CORS issues because the Capacitor Http Plugin patch the window.fetch apis
   * and does not send the ORIGIN header.
   *
   * @param req
   * @private
   */
  private interceptNativeRequest(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const { method, body, url, headers, params, responseType } = req;
    /**
     * Transforms the type signature of Angular http headers
     * to Capacitor's type signature for http headers.
     *
     * Sanitizes invalid header values from the output.
     */
    const sanitizeHeaders = (headers: HttpHeaders) => {
      const res: CapacitorHttpHeaders = {};
      for (const key of headers.keys()) {
        res[key] = decodeURIComponent(headers.get(key) || '');
      }
      return res;
    };
    /**
     * Transforms the type signature of Angular http params
     * to Capacitor's type signature for http params.
     *
     * Sanitizes invalid param values from the output.
     */
    const sanitizeParams = (params: HttpParams) => {
      const res: CapacitorHttpParams = {};
      for (const key of params.keys()) {
        res[key] = decodeURIComponent(params.get(key) || '');
      }
      return res;
    };

    const extraHeaders: CapacitorHttpHeaders = {};

    // On Android and iOS, data can only be a string or a JSON. FormData, Blob, ArrayBuffer, and other complex types
    // are only directly supported on web or through enabling CapacitorHttp in the config and using
    // the patched window.fetch or XMLHttpRequest.
    if (!(body instanceof FormData)) {
      extraHeaders['Accept'] = 'application/json';
      extraHeaders['Content-Type'] = 'application/json';
    }

    return defer(() => {
      // For now the native capacitor http does not works well with formdata so we have
      // to use the fetch api to continue to bypass the cors on mobile
      if (body instanceof FormData) {
        return this.manageFetchCall(url, {
          method,
          body,
          ...(req.withCredentials ? { webFetchExtra: { credentials: 'include' } } : {}),
          headers: {
            ...sanitizeHeaders(headers)
          }
        });
      } else {
        return this.manageNativeRequest({
          url,
          method,
          data: body || (method === 'GET' ? undefined : {}),
          headers: {
            ...sanitizeHeaders(headers),
            ...extraHeaders
          },
          params: sanitizeParams(params),
          ...(req.withCredentials ? { webFetchExtra: { credentials: 'include' } } : {}),
          responseType
        });
      }
    }).pipe(
      catchError((e) => throwError(() => this.handleRequestError(e))),
      map((res) => {
        if (res.status >= 400) {
          const errorResponse = new HttpErrorResponse({
            error: res.body,
            headers: res.headers,
            url: res.url,
            status: res.status
          });
          throw this.handleRequestError(errorResponse);
        }
        return new HttpResponse({ body: res.body });
      })
    );
  }

  private manageNativeRequest(options: HttpOptions): Observable<CommonResponse> {
    return defer(() => CapacitorHttp.request(options)).pipe(
      map((res) => {
        if (options.responseType === 'blob') {
          return { ...res, data: this.manageBlobResponseType(res.data, res.headers['Content-Type']) };
        } else {
          return res;
        }
      }),
      map((res) => ({ body: res.data, headers: new HttpHeaders(res.headers), url: res.url, status: res.status }))
    );
  }

  private manageFetchCall(url: string, requestInit: RequestInit): Observable<CommonResponse> {
    return defer(() => fetch(url, requestInit)).pipe(
      switchMap((res) =>
        defer(() => res.json()).pipe(
          map((result) => ({ body: result, status: res.status, url: res.url, headers: new HttpHeaders(res.headers) }))
        )
      )
    );
  }

  /**
   * The response type is not well managed by CapacitorHttp and returns base64 data even we ask for a blob.
   * So we have to convert from base64 to blob.
   *
   * @param base64Result
   * @private
   */
  private manageBlobResponseType(base64Result: string, contentType: string | null): Blob | null {
    const data = Uint8Array.from(atob(base64Result), (c) => c.charCodeAt(0)); //Convert to an "iterable" type accepted by Blob constructor
    return new Blob([data], { type: contentType || 'application/octet-stream' });
  }

  private interceptWebRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  private handleRequestError(error: HttpErrorResponse) {
    return error;
  }
}

import { Injectable } from '@angular/core';
import { EMPTY, firstValueFrom, from, Observable, ReplaySubject, take } from 'rxjs';
import { DarkMode, DarkModeAppearance } from '@aparajita/capacitor-dark-mode';
import { StorageService } from '../storage/storage.service';
import { switchMap } from 'rxjs/operators';
import { Util } from '@shared/utils/util';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { StatusBar, Style } from '@capacitor/status-bar';
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly DARK_COLOR = '#121212';
  private readonly LIGHT_COLOR = '#ffffff';

  private currentAppearance$ = new ReplaySubject<DarkModeAppearance>(1);
  private isDark$ = new ReplaySubject<boolean>(1);

  constructor(private readonly storageService: StorageService, private readonly platform: Platform) {
    // On récupère une premiere fois au démarrage la valeur de l'apparance séléctionnée
    // Ensuite on se base uniquement sur l'observable
    from(this.storageService.get('appearance'))
      .pipe(
        switchMap((appearance) => {
          if (!Util.isEnumValue(appearance, DarkModeAppearance)) {
            return this.setAppearance(DarkModeAppearance.dark);
          }
          this.currentAppearance$.next(appearance);
          return EMPTY;
        }),
        switchMap(() => DarkMode.update()),
        take(1)
      )
      .subscribe();
    // On regarde quand la classe css "dark" est ajouté sur le body
    // car il nous manque l'info quand le thème selectionné est celui de l'os
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(async (mutationRecord) => {
        const targetElement = mutationRecord.target as HTMLElement;
        const isDark = targetElement.classList.contains('dark');
        this.isDark$.next(isDark);
        if (this.platform.is('mobile')) {
          await StatusBar.setBackgroundColor({color: isDark ? this.DARK_COLOR : this.LIGHT_COLOR});
          await StatusBar.setStyle({style: isDark ? Style.Dark : Style.Light});
          await NavigationBar.setColor({ color: isDark ? this.DARK_COLOR : this.LIGHT_COLOR, darkButtons: !isDark });
        }
      });
    });

    observer.observe(document.querySelector('body')!, {
      attributes: true,
      attributeFilter: ['class']
    });
    // Comme le mutation observer ne se déclenche que sur modification, on doit vérifier une premiere fois
    // la présence ou non de la class dark.
    const isDark = document.querySelector('body')!.classList.contains('dark');
    if (this.platform.is('mobile')) {
      NavigationBar.setColor({color: isDark ? this.DARK_COLOR : this.LIGHT_COLOR, darkButtons: !isDark})
          .then(() => StatusBar.setBackgroundColor({color: isDark ? this.DARK_COLOR : this.LIGHT_COLOR}))
          .then(() => StatusBar.setStyle({style: isDark ? Style.Dark : Style.Light}));
    }
  }

  public isDark(): Observable<boolean> {
    return this.isDark$.asObservable();
  }

  public getAppearance(): Promise<DarkModeAppearance> {
    return firstValueFrom(this.currentAppearance$);
  }

  public setAppearance(appearance: DarkModeAppearance): Promise<void> {
    this.currentAppearance$.next(appearance);
    return this.storageService.set('appearance', appearance);
  }
}

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

type CurrentPlatform = 'browser' | 'native';

@Injectable({
  providedIn: 'root'
})
export class CurrentPlatformService {
  public currentPlatform: CurrentPlatform;

  constructor(private platform: Platform) {
    this.setCurrentPlatform();
  }

  isNative() {
    return this.currentPlatform === 'native';
  }

  isBrowser() {
    return this.currentPlatform === 'browser';
  }

  isRealAndroidDevice(): boolean {
    return this.platform.is('android') && !this.platform.is('mobileweb');
  }

  private setCurrentPlatform() {
    // Are we on mobile platform? Yes if platform is ios or android, but not desktop or mobileweb, no otherwise
    if (this.platform.is('ios') || (this.platform.is('android') && !(this.platform.is('desktop') || this.platform.is('mobileweb')))) {
      this.currentPlatform = 'native';
    } else {
      this.currentPlatform = 'browser';
    }
  }
}

export abstract class UrlUtil {
  private static readonly URI_DIVIDER: string = '/';
  private static readonly REGEX_LAST_DIVIDER: RegExp = /\/$/;
  private static readonly REGEX_FIRST_DIVIDER: RegExp = /^\//;

  static buildUrl(...parts: string[]): string {
    const clearedParts = parts.map(UrlUtil.removeEndedOrStartedUriDivider);
    return clearedParts.join(UrlUtil.URI_DIVIDER);
  }

  static removeEndedOrStartedUriDivider(part: string): string {
    return part.replace(UrlUtil.REGEX_LAST_DIVIDER, '').replace(UrlUtil.REGEX_FIRST_DIVIDER, '');
  }

  static getLastSegment(url: string): string {
    const paths = url.split('/').filter((path) => path !== '');
    return paths[paths.length - 1];
  }
}

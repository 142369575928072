export enum UserJobEnum {
  APS = 'APS', // (Agent Prévention de Sécurité)
  SSIAP1 = 'SSIAP1', // (Agent de Sécurité Incendie)
  SSIAP2 = 'SSIAP2', // (Chef d'équipe en Sécurité Incendie)
  SSIAP3 = 'SSIAP3', // (Chef de service de Sécurité Incendie)
  MC = 'MC', // (Agent Cynophile)
  ED = 'ED' // (Equipe de Direction)
}

export const userJobLabels: Record<UserJobEnum, string> = {
  [UserJobEnum.APS]: 'Agent Prévention de Sécurité',
  [UserJobEnum.SSIAP1]: 'Agent de Sécurité Incendie',
  [UserJobEnum.SSIAP2]: `Chef d'équipe en Sécurité Incendie`,
  [UserJobEnum.SSIAP3]: 'Chef de service de Sécurité Incendie',
  [UserJobEnum.MC]: 'Agent Cynophile',
  [UserJobEnum.ED]: 'Equipe de Direction'
};

import { NgModule } from '@angular/core';
import { RoomMapper } from './mappers/room.mapper';
import { RoomClient } from './services/room.client';
import { RoomDataAccess } from './data-access/room.data-access';
import { PendingUserService } from './services/pending-user.service';
import { NgxsModule } from '@ngxs/store';
import { RoomSessionState } from '@business/room/communications/data-access/state/room-session-state';

@NgModule({
  imports: [NgxsModule.forFeature([RoomSessionState])],
  providers: [RoomMapper, RoomClient, RoomDataAccess, PendingUserService]
})
export class RoomModule {}

import { Injectable } from '@angular/core';
import {
  ConnectionRequestResponseModel,
  FormattedResponse,
  MediaModel,
  PendingUserByRoomMessage,
  RoomResponse,
  UserResponse
} from '@proxima/common';
import { isDefined } from '@shared/typeguard/is-defined.type-guard';
import { RoomUser } from '@shared/models/session.models';

@Injectable()
export class RoomMapper {
  public mapFormattedRoomResponse(res: FormattedResponse<RoomResponse>): RoomResponse {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('room data are missing');
  }

  public mapFormattedRoomResponseArray(res: FormattedResponse<RoomResponse[]>): RoomResponse[] {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('rooms data are missing');
  }

  public mapFormattedPendingUserByOwnerResponseArray(res: FormattedResponse<PendingUserByRoomMessage[]>): PendingUserByRoomMessage[] {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('user data are missing');
  }

  public mapUserResponseToRoomUser(user: UserResponse, roomId: string): RoomUser {
    if (isDefined(user)) {
      return {
        id: user.id,
        name: user.name,
        role: user.role,
        online: user.online,
        avatar: user.avatar,
        color: user.color,
        job: user.job,
        isSpeaking: user.isSpeaking ?? false,
        connectedToRoom: user.connectedRoom === roomId
      };
    }
    throw new Error('user response is empty');
  }

  public mapFormattedRoomConnectionRequestResponse(res: FormattedResponse<ConnectionRequestResponseModel>): ConnectionRequestResponseModel {
    if (isDefined(res.result)) {
      return { ...res.result, expiresAt: new Date(res.result.expiresAt) };
    }
    throw new Error('connection request empty');
  }

  public mapFormattedRoomJoinIdResponse(res: FormattedResponse<string>): string {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('joinId empty');
  }

  public mapFormattedRoomAvatarResponse(res: FormattedResponse<MediaModel>): MediaModel {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('avatar empty');
  }
}

import { NgModule } from '@angular/core';
import { AvatarDataAccess } from '@business/avatar/data-access/avatar.data-access';
import { AvatarService } from '@business/avatar/data-access/service/avatar.service';
import { NgxsModule } from '@ngxs/store';
import { AvatarState } from '@business/avatar/data-access/state/avatar.state';

@NgModule({
  imports: [NgxsModule.forFeature([AvatarState])],
  providers: [AvatarDataAccess, AvatarService]
})
export class AvatarModule {}

import { Injectable } from '@angular/core';
import { AvatarDataAccess } from '@business/avatar/data-access/avatar.data-access';
import { Observable } from 'rxjs';
import { AvatarEntityModel } from '@business/avatar/data-access/state/avatar.model';

@Injectable()
export class AvatarService {
  constructor(private readonly avatarDataAccess: AvatarDataAccess) {}

  public getRoomAvatar(avatarId: string, roomId: string): Observable<AvatarEntityModel> {
    return this.avatarDataAccess.get(avatarId, roomId, true);
  }

  public getUserAvatar(avatarId: string, userId: string): Observable<AvatarEntityModel> {
    return this.avatarDataAccess.get(avatarId, userId, false);
  }
}

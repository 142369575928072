import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PATHS } from '@shared/constants/route.constants';
import { AuthDataAccess } from '@business/authentication/communications/data-access/auth.data-access';

@Injectable({
  providedIn: 'root'
})
export class IsStillLoggedInGuard  {
  private isFirstLoad = true;
  constructor(private readonly authDataAccess: AuthDataAccess, private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (this.isFirstLoad) {
      return this.authDataAccess.refreshToken().pipe(
        switchMap((accessToken) => {
          this.isFirstLoad = false;
          if (accessToken) {
            return of(this.router.createUrlTree([PATHS.DASHBOARD]));
          }
          return of(true);
        }),
        catchError(() => {
          this.isFirstLoad = false;
          return of(true);
        })
      );
    } else {
      return of(true);
    }
  }
}

import { Injectable } from '@angular/core';
import { UserClient } from '@business/user/communications/services/user.client';
import { catchError, Observable, of, retry, take } from 'rxjs';
import { MediaModel, RxOperatorUtil, UserResponse } from '@proxima/common';
import { filter, map, switchMap } from 'rxjs/operators';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { Store } from '@ngxs/store';
import { UserState } from '@business/user/communications/data-access/state/user.state';
import { ResetUser, SetUser, SetUserLoadingStatus } from '@business/user/communications/data-access/state/user.actions';

@Injectable()
export class UserDataAccess {
  constructor(
    private readonly client: UserClient,
    private readonly store: Store
  ) {}

  public updateUserAvatar(userId: string, avatar: File): Observable<MediaModel> {
    return this.client.updateUserAvatar(userId, avatar);
  }

  public resetUserInfo(): Observable<void> {
    return this.store.dispatch(new ResetUser());
  }

  public getUserInfo(): Observable<UserResponse> {
    return this.refreshIfNeeded().pipe(
      switchMap(() => this.store.select(UserState.getUser)),
      RxOperatorUtil.filterNullOrUndefined()
    );
  }

  public getUserId(): Observable<string> {
    return this.getUserInfo().pipe(map((user) => user.id));
  }

  public refresh(): Observable<void> {
    return this.store.dispatch(new SetUserLoadingStatus(LoadingState.LOADING)).pipe(
      switchMap(() => {
        return this.client.me();
      }),
      switchMap((user: UserResponse) => this.store.dispatch([new SetUser(user), new SetUserLoadingStatus(LoadingState.LOADED)])),
      retry(1),
      //TODO déconnecter l'utilisateur
      catchError(() => this.store.dispatch([new SetUserLoadingStatus(LoadingState.LOADED)]))
    );
  }

  private refreshIfNeeded(): Observable<void> {
    return this.shouldRefresh().pipe(
      switchMap((shouldRefresh) => (shouldRefresh ? this.refresh() : of(null))),
      switchMap(() => this.store.select(UserState.getLoadingState)),
      filter((loadingState) => loadingState === LoadingState.LOADED),
      map(() => void 0)
    );
  }

  private shouldRefresh(): Observable<boolean> {
    return this.store.select(UserState.getLoadingState).pipe(
      map((loadingState) => loadingState === LoadingState.NOT_LOADED),
      take(1)
    );
  }
}

export enum BadRequestErrorCode {
  CANNOT_BAN_YOURSELF,
  NEW_OLD_PASSWORDS_NOT_MATCHING,
  USE_SAME_PASSWORD,
  CURRENT_PASSWORD_NOT_MATCHING,
  NEW_AND_CONFIRMED_NEW_PASSWORD_NOT_MATCHING,
  USERNAME_ALREADY_EXIST,
  EMAIL_ALREADY_EXIST,
  NOT_OBJECT_ID_FORMAT,
  CANNOT_LEAVE_YOUR_ROOM,
  INVALID_IMAGE,
  INVALID_AUDIO,
  UPLOAD_FILE_FAILED
}

import { UserResponse } from '@proxima/common';
import { LoadingState } from '@shared/models/enums/loading-state.enum';

export class SetUser {
  static readonly type = '[User] Set user';
  constructor(public readonly user: UserResponse) {}
}

export class ResetUser {
  static readonly type = '[User] Reset user';
}

export class SetUserLoadingStatus {
  public static type = '[User] Set user loading status';

  constructor(public readonly loadingState: LoadingState) {}
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PATHS } from '@shared/constants/route.constants';
import { IsStillLoggedInGuard } from './guards/is-still-logged-in.guard';
import { AuthenticatedGuard } from './guards/authenticated-guard';
import { AppReadyComponent } from './presentation/pages/app-ready/app-ready.component';

const routes: Routes = [
  {
    path: PATHS.LOGIN,
    canActivate: [IsStillLoggedInGuard],
    loadChildren: () => import('./presentation/pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: '',
    component: AppReadyComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PATHS.DASHBOARD
      },
      {
        path: PATHS.DASHBOARD,
        loadChildren: () => import('./presentation/pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: PATHS.DASHBOARD
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { catchError, defer, Observable, of, scan } from 'rxjs';
import { MediaBlobResponse, MediaBlobResponseStateEnum } from '@shared/models/media-blob-response';
import { map, switchMap } from 'rxjs/operators';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { ReadFileResult, StatResult } from '@capacitor/filesystem/dist/esm/definitions';
import { Platform } from '@ionic/angular';
import { acceptedMimeType } from '@shared/constants/mime-type.constants';

@Injectable({ providedIn: 'root' })
export class MediaResolverService {
  private headers = {
    'Content-Type': 'application/json'
  };

  constructor(
    private readonly http: HttpClient,
    private readonly platform: Platform
  ) {}

  public getMediaBlobResponse(url: string, withCredentials = true): Observable<MediaBlobResponse> {
    //TODO faire des vérifications sur l'url pour qu'elle pointe sur un serveur proxima
    return this.http
      .get(url, {
        headers: this.headers,
        withCredentials,
        observe: 'events',
        reportProgress: true,
        responseType: 'blob'
      })
      .pipe(
        scan(
          (previous: MediaBlobResponse, event: HttpEvent<Blob>): MediaBlobResponse => {
            if (this.isHttpProgressEvent(event)) {
              return {
                progress: event.total ? Math.round((100 * event.loaded) / event.total) : previous.progress,
                state: MediaBlobResponseStateEnum.IN_PROGRESS,
                content: null
              };
            }
            if (this.isHttpResponse(event)) {
              return {
                progress: 100,
                state: MediaBlobResponseStateEnum.DONE,
                content: event.body
              };
            }
            return previous;
          },
          { state: MediaBlobResponseStateEnum.PENDING, progress: 0, content: null }
        ),
        catchError(() => of({ state: MediaBlobResponseStateEnum.ERROR, progress: 0, content: null }))
      );
  }

  public createImageFromBlob(blob: Blob | null): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        observer.next(reader.result as string);
        this.removeListeners(reader);
        observer.complete();
      });

      reader.addEventListener('error', () => {
        observer.next('/assets/svg/broken-image.svg');
        this.removeListeners(reader);
        observer.complete();
      });

      if (blob && blob.size > 0) {
        reader.readAsDataURL(blob);
      } else {
        observer.error('no blob provided');
      }
    });
  }

  public createAudioFromBlob(blob: Blob | null): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        observer.next(reader.result as string);
        this.removeListeners(reader);
        observer.complete();
      });

      reader.addEventListener('error', (err) => {
        observer.error(err);
        observer.complete();
      });

      if (blob) {
        reader.readAsDataURL(blob);
      } else {
        observer.error('no blob provided');
      }
    });
  }

  public createFolderIfNotdefined(roomOrUserId: string): Observable<boolean> {
    return this.isFolderExist(roomOrUserId).pipe(
      switchMap((isFolderExist) => {
        if (!isFolderExist) {
          return defer(() => Filesystem.mkdir({ directory: Directory.Documents, path: roomOrUserId })).pipe(
            map(() => true),
            catchError(() => of(false))
          );
        } else {
          return of(true);
        }
      })
    );
  }

  public isFolderExist(roomOrUserId: string): Observable<boolean> {
    return defer(() => Filesystem.stat({ directory: Directory.Documents, path: roomOrUserId })).pipe(
      map((res: StatResult) => res.type === 'directory'),
      catchError(() => of(false))
    );
  }

  public createFileOnDevice(imageId: string, roomOrUserId: string, base64Content: string, extension = 'webp'): Observable<void> {
    return this.createFolderIfNotdefined(roomOrUserId).pipe(
      switchMap((isCreated: boolean) => {
        if (isCreated) {
          return defer(() =>
            Filesystem.writeFile({
              path: `${roomOrUserId}/${imageId}.${extension}`,
              data: base64Content,
              directory: Directory.Documents
            })
          ).pipe(
            map(() => void 0),
            catchError(() => of(void 0))
          );
        } else {
          return of(void 0);
        }
      })
    );
  }

  public getFileIfAlreadySavedOnDevice(
    imageId: string,
    roomOrUserId: string,
    callback?: (blobResponse: MediaBlobResponse) => void,
    mimeType = acceptedMimeType.WEBP
  ): Observable<string | null> {
    if (this.platform.is('mobile')) {
      return defer(() =>
        Filesystem.stat({ path: `${roomOrUserId}/${imageId}.${mimeType.extension}`, directory: Directory.Documents })
      ).pipe(
        switchMap((stat) => {
          if (stat) {
            return defer(() =>
              Filesystem.readFile({
                path: `${roomOrUserId}/${imageId}.${mimeType.extension}`,
                directory: Directory.Documents
              })
            );
          } else {
            throw new Error();
          }
        }),
        switchMap((fileData: ReadFileResult) => {
          // Convertir les données du fichier en base64. Data est une string quand exécuté depuis le natif.
          const base64Data = fileData.data as string;

          // Créer un blob à partir des données base64
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const blob = new Blob([new Uint8Array(byteNumbers)], { type: mimeType.mimeType });
          if (callback) {
            callback({ state: MediaBlobResponseStateEnum.DONE, content: blob, progress: 100 });
          }

          return of(blob); // Retour de l'objet Blob
        }),
        switchMap((mediaBlob: Blob) => this.createImageFromBlob(mediaBlob)),
        catchError(() => of(null))
      );
    }
    // sur Desktop on sauvegarde rien en locale.
    return of(null);
  }

  private removeListeners(reader: FileReader): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reader.removeAllListeners('load');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reader.removeAllListeners('error');
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  private isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { AuthMapper } from '../mappers/auth.mapper';
import {
  AnonymousLoginDtoType,
  AnonymousRegisterDtoType,
  CsrfTokenResponse,
  FormattedResponse,
  LoginDtoType,
  LoginResponse,
  RefreshTokenDtoType,
  UrlUtil
} from '@proxima/common';
import { IS_PUBLIC_API } from '@shared/constants/api-context.constants';
import { AuthenticationApiUrl } from '../constants/api-url.constants';

@Injectable()
export class AuthClient {
  private headers = {
    'Content-Type': 'application/json'
  };
  constructor(
    private readonly http: HttpClient,
    private readonly authMapper: AuthMapper
  ) {}

  public getCsrfToken(): Observable<string> {
    return this.http
      .get<FormattedResponse<CsrfTokenResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.csrfToken), {
        headers: this.headers,
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.authMapper.mapFormattedCsrfResponse(res)));
  }

  public login(body: LoginDtoType): Observable<string> {
    const url = UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.login);
    return this.http
      .post<FormattedResponse<LoginResponse>>(url, body, {
        headers: this.headers,
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.authMapper.mapFormattedLoginResponse(res)));
  }

  public anonymousRegister(body: AnonymousRegisterDtoType, avatar?: File): Observable<string> {
    const formData: FormData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (avatar) {
      formData.append('avatar', avatar);
    }
    return this.http
      .post<FormattedResponse<LoginResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.anonymousRegister), formData, {
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.authMapper.mapFormattedLoginResponse(res)));
  }

  public anonymousLogin(body: AnonymousLoginDtoType): Observable<string> {
    return this.http
      .post<FormattedResponse<LoginResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.anonymousLogin), body, {
        headers: this.headers,
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.authMapper.mapFormattedLoginResponse(res)));
  }

  public refreshToken(body: RefreshTokenDtoType): Observable<string> {
    return this.http
      .post<FormattedResponse<LoginResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.refreshToken), body, {
        headers: this.headers,
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.authMapper.mapFormattedLoginResponse(res)));
  }

  public logout(): Observable<void> {
    return this.http
      .delete<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.logout), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }
}

import { AuthorizationStatusEnum } from '@shared/models/enums/authorization-status.enum';
import { BannedFromRoomMessage, DisconnectFromRoomMessage, LeftRoomMessage } from '@proxima/common';
import { HttpException } from '@nestjs/common/exceptions/http.exception';

export class JoinRoomAgreement {
  static readonly type = '[Room Events] Join room agreement';

  constructor(
    public readonly roomName: string,
    public readonly authorizationStatus: AuthorizationStatusEnum,
    public readonly originalError?: HttpException
  ) {}
}

export class LogoutSoon {
  static readonly type = '[Room Events] Logout soon';
}

export class ConnectionStatus {
  static readonly type = '[Room Events] Connection status';

  constructor(public readonly isConnected: boolean) {}
}

export class NewUserInRoom {
  static readonly type = '[Room Events] New user in room';

  constructor(public readonly newUserName: string) {}
}

export class UserDisconnectFromRoom {
  static readonly type = '[Room Events] User disconnect from room';

  constructor(
    public readonly userDisconnectedMessage: DisconnectFromRoomMessage,
    public readonly currentUserId: string
  ) {}
}

export class UserLeftRoom {
  static readonly type = '[Room Events] User left room';

  constructor(
    public readonly userLeftMessage: LeftRoomMessage,
    public readonly currentUserId: string
  ) {}
}

export class BannedUserInRoom {
  static readonly type = '[Room Events] Banned user in room';

  constructor(
    public readonly bannedUserMessage: BannedFromRoomMessage,
    public readonly currentUserId: string
  ) {}
}

export class DeletedRoom {
  static readonly type = '[Room Events] Deleted room';

  constructor(public readonly roomName: string) {}
}

export class CreateRoom {
  static readonly type = '[Room Events] Create room';

  constructor(public readonly roomName: string) {}
}

export class JoinRoomRefused {
  static readonly type = '[Room Events] Join room refused';

  constructor(public readonly roomName: string) {}
}

export class RoomAvatarUpdated {
  static readonly type = '[Room Events] Room avatar updated';
}

/**
 * Uniquement à usage de dev. Devra être supprimé
 */
export class RoomCurrentUserSpeakingStatus {
  static readonly type = '[Room Events] Room current user speaking status';

  constructor(public readonly isSpeaking: boolean) {}
}

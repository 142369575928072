import { NotFoundErrorCode } from './not-found/not-found-error-code';
import { PreconditionFailedErrorCode } from './precondition-failed/precondition-failed-error-code';
import { UnauthorizedErrorCode } from './unauthorized/unauthorized-error-code';
import { BadRequestErrorCode } from './bad-request/bad-request-error-code';

type ErrorEnum = typeof NotFoundErrorCode | typeof PreconditionFailedErrorCode | typeof UnauthorizedErrorCode | typeof BadRequestErrorCode;

export const getErrorCodeByType: (
  statusCode: number,
  expectedErrorCode: number | number[],
  expectedErrorEnum: ErrorEnum,
  errorCode?: number
) => boolean = (statusCode: number, expectedErrorCode: number | number[], expectedErrorEnum: ErrorEnum, errorCode?: number) => {
  let errorEnum;
  switch (statusCode) {
    // NOT found
    case 404:
      errorEnum = NotFoundErrorCode;
      break;
    // Precondition failed
    case 412:
      errorEnum = PreconditionFailedErrorCode;
      break;
    //Unauthorized
    case 401:
      errorEnum = UnauthorizedErrorCode;
      break;
    // Bad request
    case 400:
      errorEnum = BadRequestErrorCode;
      break;
    default:
      errorEnum = null;
      break;
  }

  if (errorEnum && typeof errorCode === 'number') {
    if (Array.isArray(expectedErrorCode)) {
      let correspondingErrorValue = '';
      const errorIndexes = Object.entries(errorEnum).reduce((acc, [errCodeKey, errCodeValue]) => {
        // Si dans la liste que l'on a fourni en paramètre, ça match l'item courant, on le rajoute dans l'accumulateur
        if (expectedErrorCode.includes(errCodeValue)) {
          acc.push(errCodeKey);
        }
        // On en profite pour récupérer le nom de la clé associé à l'erreur qui a été jeté
        // Si l'erreur est par exemple : 401 avec pour valeur 6, alors on tente de récupérer dans l'enum
        // Unauthorized (401) le nom de la clé associé à la valeur 6 (TOKEN_NOT_FOUND)
        if (Number(errCodeKey) === errorCode) {
          correspondingErrorValue = errCodeValue;
        }
        return acc;
      }, [] as Array<string>);
      // Si par exemple TOKEN_NOT_FOUND est dans la liste des erreurs que l'on fourni dans le tableau d'entrée
      // alors on retourne true. ça veut dire que l'erreur reçu fait bien parti d'un des items de la liste que l'on
      // a fourni en paramètre.
      // Par exemple pour gérer le refresh token dans l'interceptor d'erreur, si on a une erreur faisait partie de
      // cette liste [UnauthorizedErrorCode.EXPIRED_REFRESH_TOKEN,
      //           UnauthorizedErrorCode.TOKEN_NOT_FOUND,
      //           UnauthorizedErrorCode.AUTHORIZATION_TYPE_NOT_VALID,
      //           UnauthorizedErrorCode.NOT_VALID_TOKEN]
      // alors on retourne true
      return errorIndexes.includes(correspondingErrorValue);
    } else {
      const errorIndex = Object.values(errorEnum).indexOf(errorCode as unknown as typeof errorEnum);
      return Object.keys(errorEnum)[errorIndex] === getEnumKeyByValue(expectedErrorCode, expectedErrorEnum);
    }
  }
  return false;
};

const getEnumKeyByValue = (errorCode: number, errorEnum: ErrorEnum) => Object.keys(errorEnum)[Object.values(errorEnum).indexOf(errorCode)];

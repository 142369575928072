import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  socketEndpoint: 'wss://api.proximatrust.com',
  apiEndpoint: 'https://api.proximatrust.com/api',
  webRtcDiscoveryServer: {
    iceServers: [
      {
        urls: 'turn:turn.proximatrust.com',
        username: 'coturn_admin_yBOflyq2BtpT',
        credential: 'dklie!Ul31ITcaB^2!hj'
      }
    ]
  },
  isLocationMocked: false
};

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable } from 'rxjs';

type AcceptedStorageValueTypes = string | number | null | undefined;

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private appStorage: Storage;

  constructor(private readonly storage: Storage) {}

  /**
   * L'initialisation est appelé dans le main.ts avant que le plugin dark mode n'appel le getter et le setter
   */
  async init() {
    const storage = await this.storage.create();
    this.appStorage = storage;
  }

  public set(key: string, value: AcceptedStorageValueTypes): Promise<void> {
    return this.appStorage.set(key, value);
  }

  public get(key: string): Observable<AcceptedStorageValueTypes> {
    return from(this.appStorage.get(key));
  }
}

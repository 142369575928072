import { Router, UrlTree } from '@angular/router';
import { catchError, exhaustMap, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PATHS } from '@shared/constants/route.constants';
import { ClientSenderSocketService } from '../services/websocket/client-sender-socket/client-sender-socket.service';
import { LoggedInService } from '@business/authentication/services/logged-in.service';
import { KeepAliveService } from '@business/authentication/services/keep-alive.service';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';
import { PermissionService } from '../services/permission/permission.service';
import { AuthDataAccess } from '@business/authentication/communications/data-access/auth.data-access';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard {
  constructor(
    private readonly clientSender: ClientSenderSocketService,
    private readonly loggedInService: LoggedInService,
    private readonly authDataAccess: AuthDataAccess,
    private readonly userDataAccess: UserDataAccess,
    private readonly keepAlive: KeepAliveService,
    private readonly permissions: PermissionService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.clientSender.isInitialized().pipe(
      filter((isInit) => isInit),
      switchMap(() =>
        this.loggedInService.loggedIn$.pipe(
          exhaustMap((isLoggedIn: boolean) => {
            if (isLoggedIn) {
              return this.permissions.isAllPermissionsGranted().pipe(
                switchMap((isGranted: boolean) => {
                  if (isGranted) {
                    return this.handleGrantedUser();
                  } else {
                    return this.authDataAccess.logout().pipe(
                      catchError(() => of(void 0)),
                      // Permet de redéclencher dans la page login la modale pour modifier les permissions
                      tap(() => this.permissions.refreshPermissionNeeded()),
                      switchMap(() => of(this.router.createUrlTree([PATHS.LOGIN])))
                    );
                  }
                })
              );
            } else {
              return of(this.router.createUrlTree([PATHS.LOGIN]));
            }
          })
        )
      )
    );
  }

  private handleGrantedUser(): Observable<boolean | UrlTree> {
    this.keepAlive.shouldListenKeepAlive(true);
    return this.userDataAccess.getUserInfo().pipe(
      map((user) => !!user),
      catchError(() => {
        // TODO afficher un message d'erreur comme quoi on a pas pu récupérer les infos utilisateur
        return this.authDataAccess.logout().pipe(
          catchError(() => of(void 0)),
          switchMap(() => of(this.router.createUrlTree([PATHS.LOGIN])))
        );
      })
    );
  }
}

import { from, Observable, race, throwError, timeout } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { MiscEvents, uuidv4 } from '@proxima/common';

// Le paramètre que l'evenement d'envoi et de retour doivent avoir
interface ReservedAttributes {
  eventId: string;
}

// Un object peut contenir n'importe quelle clé mais obligatoirement "eventId"
type RequiredEventId = Record<string, any> & ReservedAttributes;

// Type qui ne doit pas contenir EventId. la valeur de retour de l'observable n'a pas besoin de cette valeur.
type ValidAttributes = Record<string, any> & Partial<Record<keyof ReservedAttributes, never>>;

/**
 * Evenement qui attend une réponse.
 * Attention: La fonction côté serveur doit obligatoirement retourner un objet pour que la callback puisse se déclencher
 *
 * @param socket
 * @param eventName
 * @param eventData
 */
export const emitEventWithCallback = <T, U extends RequiredEventId, R extends ValidAttributes>(
  socket: Socket,
  eventName: string,
  eventData: T
): Observable<R> => {
  const newEventId = uuidv4();
  const eventDataWithEventId = { ...eventData, eventId: newEventId };
  return race(
    // On transforme la callback en un observable
    new Observable((observer) => {
      socket.emit(eventName, eventDataWithEventId, (message: U) => {
        observer.next(message);
        observer.complete();
      });
    }),
    from(socket.fromOneTimeEvent<U>(MiscEvents.exception)).pipe(
      // On vérifie que l'id de l'evenement envoyé correspond pour ne pas intercepter une autre exception
      filter((exception) => !!Object.getOwnPropertyDescriptor(exception, 'eventId') && exception.eventId === newEventId),
      switchMap((exception) => throwError(() => exception))
    )
  ).pipe(
    timeout(30000),
    map((message) => {
      const { eventId: _, ...clone } = message as U;
      // TODO améliorer le typage
      return clone as R;
    })
  );
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormattedResponse, MediaModel, UrlUtil, UserResponse } from '@proxima/common';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { UserMapper } from '@business/user/communications/mappers/user.mapper';
import { UserApiUrl } from '@business/user/communications/constants/api-url.constants';
import { AuthenticationApiUrl } from '@business/authentication/communications/constants/api-url.constants';

@Injectable()
export class UserClient {
  private headers = {
    'Content-Type': 'application/json'
  };
  constructor(
    private readonly http: HttpClient,
    private readonly userMapper: UserMapper
  ) {}

  public updateUserAvatar(userId: string, avatar: File): Observable<MediaModel> {
    const craftedUrlWithId = UserApiUrl.updateUserAvatar.replace('%id', userId);
    const formData: FormData = new FormData();
    formData.append('avatar', avatar);

    return this.http
      .put<FormattedResponse<MediaModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), formData, {
        withCredentials: true
      })
      .pipe(map((res) => this.userMapper.mapFormattedUserAvatarResponse(res)));
  }

  public me(): Observable<UserResponse> {
    return this.http
      .get<FormattedResponse<UserResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, AuthenticationApiUrl.me), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.userMapper.mapFormattedUserResponse(res)));
  }
}

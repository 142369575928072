export enum NotFoundErrorCode {
  ROOM_NOT_FOUND,
  ROOM_NOT_FOUND_OR_DONT_OWN_ROOM,
  ROOM_NOT_FOUND_OR_DONT_MEMBER,
  USER_NOT_FOUND_IN_PENDING_ZONE,
  USER_NOT_FOUND_IN_ROOM,
  USER_NOT_FOUND,
  RECOVER_CODE_VALIDATION,
  RECOVER_CODE_HAS_EXPIRED,
  FILE_NOT_FOUND,
  CONNECTION_REQUEST_MISMATCH
}

import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { AudioMessageResponseModel, FetchMessagesDtoType, ImageMessageResponseModel, MessageWithLimitResponseModel } from '@proxima/common';
import { MessageClient } from '../communications/client/message.client';

@Injectable()
export class MessageDataAccess {
  constructor(private readonly client: MessageClient) {}

  getRoomMessages(roomId: string, query: FetchMessagesDtoType = {}): Observable<MessageWithLimitResponseModel> {
    return this.client.getRoomMessages(roomId, query).pipe(catchError(() => of({ isLastPage: false, messages: [] })));
  }

  uploadImageMessage(roomId: string, avatar: File, legend?: string): Observable<ImageMessageResponseModel> {
    return this.client.uploadImageMessage(roomId, avatar, legend);
  }

  uploadAudioMessage(roomId: string, audio: File): Observable<AudioMessageResponseModel> {
    return this.client.uploadAudioMessage(roomId, audio);
  }
}

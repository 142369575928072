import { distinctUntilChanged, ReplaySubject, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { JwtTokenService } from './jwt-token.service';
import { ClientSenderSocketService } from '../../../services/websocket/client-sender-socket/client-sender-socket.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {
  loggedIn$ = new ReplaySubject<boolean>(1);

  constructor(private readonly clientSender: ClientSenderSocketService, private readonly jwtService: JwtTokenService) {
    zip([this.jwtService.isTokenExpired(), this.jwtService.getAccessToken()])
      .pipe(
        distinctUntilChanged(),
        tap(([isTokenExpired, accessToken]: [boolean, string]) => {
          this.loggedIn$.next(!isTokenExpired);
          if (isTokenExpired) {
            console.error('token expired so we disconnect the socket');
            this.clientSender.disconnect();
          } else {
            if (this.clientSender.isConnectedSnapshot()) {
              this.clientSender.refreshToken(accessToken);
            } else {
              this.clientSender.setAccessToken(accessToken);
              this.clientSender.connect();
            }
          }
        })
      )
      .subscribe();
  }
}

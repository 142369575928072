import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, defer, Observable, pairwise, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { PATHS } from '@shared/constants/route.constants';
import { AuthDataAccess } from '@business/authentication/communications/data-access/auth.data-access';
import { CustomErrorEnum } from '@shared/models/enums/custom-error.enum';
import { CustomError } from '@shared/utils/custom-error';
import { AuthenticationApiUrl } from '@business/authentication/communications/constants/api-url.constants';
import { getErrorCodeByType, UnauthorizedErrorCode } from '@proxima/common';
import { isHttpErrorResponse } from '@shared/typeguard/is-http-error.response';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly dataAccess: AuthDataAccess
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // le jeton CSRF est manquant
        if (err.status === 403) {
          // recréer un token csrf
          this.dataAccess.reloadCsrfToken();
          return this.dataAccess.getCsrfToken().pipe(
            // Comme on renouvelle le jeton, si on s'abonne maintenant à getCsrfToken,
            // l'observable va retourner la valeur en cache or on veut la nouvelle,
            // avec Pairwise on attend que l'observable emette 2 fois.
            // il va donc emettre ["", "nouveau Token"]
            // une fois qu'on a le nouveau token on peut relancer la requête et ainsi dans l'intercepteur
            // auth.interceptor.ts quand il va demander le token csrf il sera disponible
            pairwise(),
            switchMap(() => next.handle(request)),
            catchError(() => {
              return defer(() => this.router.navigate([PATHS.LOGIN])).pipe(
                switchMap(() => throwError(() => new Error('refresh token refresh has failed')))
              );
            })
          );
        } else if (err.status === 401) {
          // l'access token n'est plus valide
          return this.handle401Error(request, err, next);
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  private addToken(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Si l'erreur 401 est du à un refersh token, on throw direct une erreur sinon on tente un refresh token
   *
   * @param request
   * @param next
   * @private
   */
  private handle401Error(request: HttpRequest<unknown>, err: HttpErrorResponse, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isRefreshTokenUrl = AuthenticationApiUrl.isRefreshTokenUrl(request.url);
    // TODO valider le comportement. On ne doit appeler refresh token que  si le token est invalide,
    //  expiré (normalement géré par le keep alive), type authorization non valide (pas utilisé le bearer,
    //  pas possible normalement) et si le token n'a pas été trouvé dans les headers
    const isExpiredToken =
      isHttpErrorResponse(err) &&
      getErrorCodeByType(
        err.error.statusCode,
        [
          UnauthorizedErrorCode.EXPIRED_REFRESH_TOKEN,
          UnauthorizedErrorCode.TOKEN_NOT_FOUND,
          UnauthorizedErrorCode.AUTHORIZATION_TYPE_NOT_VALID,
          UnauthorizedErrorCode.NOT_VALID_TOKEN
        ],
        UnauthorizedErrorCode,
        err.error.errorCode
      );
    if (!isRefreshTokenUrl && isExpiredToken) {
      return this.dataAccess.refreshToken().pipe(switchMap((accessToken: string) => next.handle(this.addToken(request, accessToken))));
    } else {
      if (isRefreshTokenUrl) {
        return throwError(() => new CustomError('refresh token refresh has failed', CustomErrorEnum.REFRESH_TOKEN_RENEWAL_FAILED));
      } else {
        return throwError(() => err);
      }
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { AuthClient } from '../services/auth.client';
import { BehaviorSubject, catchError, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { JwtTokenService } from '../../services/jwt-token.service';
import { AnonymousLoginDtoType, AnonymousRegisterDtoType, LoginDtoType, RefreshTokenDtoType } from '@proxima/common';
import { CustomError } from '@shared/utils/custom-error';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';

enum InteractionTypeEnum {
  RESET,
  RELOAD
}

@Injectable()
export class AuthDataAccess implements OnDestroy {
  constructor(
    private readonly client: AuthClient,
    private readonly userDataAccess: UserDataAccess,
    private readonly jwtService: JwtTokenService
  ) {}

  private csrfToken$: Observable<string>;
  private reloadCsrfToken$ = new BehaviorSubject<InteractionTypeEnum>(InteractionTypeEnum.RELOAD);

  // TODO: gérer les cas d'erreur
  public getCsrfToken(): Observable<string> {
    if (!this.csrfToken$) {
      this.csrfToken$ = this.reloadCsrfToken$.pipe(
        switchMap((interactionType: InteractionTypeEnum) => {
          if (interactionType === InteractionTypeEnum.RELOAD) {
            return this.client.getCsrfToken().pipe(catchError(() => of('')));
          } else {
            return of('');
          }
        }),
        shareReplay(1)
      );
    }
    return this.csrfToken$;
  }

  public reloadCsrfToken(): void {
    this.reloadCsrfToken$.next(InteractionTypeEnum.RELOAD);
  }

  public resetCsrfToken(): void {
    this.reloadCsrfToken$.next(InteractionTypeEnum.RESET);
  }

  public refreshToken(body: RefreshTokenDtoType = {}): Observable<string> {
    return of(void 0).pipe(
      switchMap(() => this.client.refreshToken(body)),
      tap((accessToken: string) => this.setAccessToken(accessToken)),
      catchError((err) => {
        this.jwtService.setAccessToken('');
        if (err instanceof CustomError) {
          throw err;
        }
        return of('');
      })
    );
  }

  public login(body: LoginDtoType): Observable<string> {
    return this.client.login(body).pipe(
      tap((accessToken: string) => this.setAccessToken(accessToken)),
      catchError((err) => {
        this.jwtService.setAccessToken('');
        throw err;
      })
    );
  }

  public logout(): Observable<void> {
    return this.client.logout().pipe(
      tap(() => {
        this.resetAccessToken();
        this.resetCsrfToken();
        this.userDataAccess.resetUserInfo();
      })
    );
  }

  public anonymousRegister(body: AnonymousRegisterDtoType, avatar?: File): Observable<string> {
    return this.client.anonymousRegister(body, avatar).pipe(
      tap((accessToken: string) => this.setAccessToken(accessToken)),
      catchError(() => {
        this.jwtService.setAccessToken('');
        return of('');
      })
    );
  }

  public anonymousLogin(body: AnonymousLoginDtoType): Observable<string> {
    return this.client.anonymousLogin(body).pipe(
      tap((accessToken: string) => this.setAccessToken(accessToken)),
      catchError(() => {
        this.jwtService.setAccessToken('');
        return of('');
      })
    );
  }

  ngOnDestroy(): void {
    this.reloadCsrfToken$.complete();
  }

  private resetAccessToken(): void {
    this.jwtService.setAccessToken('');
  }

  private setAccessToken(newToken: string): void {
    this.jwtService.setAccessToken(newToken);
  }
}

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { BehaviorSubject, concatMap, from, merge, mergeAll, Observable, Subject, withLatestFrom } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  AddIceCandidateMessage,
  AudioRtcEvents,
  AuthEvents,
  BannedFromRoomMessage,
  DisconnectFromRoomMessage,
  GeoLocationSenderMessage,
  JoinedRoomMessage,
  JoinRoomDtoType,
  JoinRoomRequestMessage,
  LatLngPosition,
  LeftRoomMessage,
  MessageEvents,
  MessageUnionModel,
  MiscEvents,
  PendingUserByRoomMessage,
  RoomAvatarUpdatedMessage,
  RoomEvents,
  RoomMessageDtoType,
  RoomPendingApprovalMessage,
  RoomPendingApprovalOwnerMessage,
  RoomSubscriptionStatus,
  ServerErrorMessage,
  UpdateRoomDescriptionMessage,
  UserAvatarUpdatedMessage,
  UserEmergencyAlertMessage,
  UserEvents,
  UserSpeakingMessage,
  WebRtcAnswerMessage,
  WebRtcOfferMessage
} from '@proxima/common';
import { emitEventWithCallback } from '@shared/utils/emit-event-with-callback';
import { WsDisconnectionReasonConstants } from '@shared/constants/ws-disconnection-reason.constants';
import {
  AddConnectedUserToRoom,
  QuitRoom,
  RemoveConnectedUserToRoom,
  RemoveUserToRoom,
  ResetConnectedUsersToRoom,
  UpdateMemberAvatar,
  UpdateRoomAvatar,
  UpdateRoomDescription
} from '@business/room/communications/data-access/state/room-session.actions';
import {
  BannedUserInRoom,
  DeletedRoom,
  NewUserInRoom,
  RoomAvatarUpdated,
  UserDisconnectFromRoom,
  UserLeftRoom as UserLeftRoomSocket
} from '@business/room/communications/data-access/state/room-events.actions';
import { RoomSessionState } from '@business/room/communications/data-access/state/room-session-state';
import { NewRoomMessage } from '@business/message/data-access/state/message.action';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';
import { UserState } from '@business/user/communications/data-access/state/user.state';
import { ConnectionStatusEnum } from '@shared/models/enums/connection-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ClientSenderSocketService {
  private connectionStatus$: BehaviorSubject<ConnectionStatusEnum> = new BehaviorSubject<ConnectionStatusEnum>(
    ConnectionStatusEnum.CONNECTING
  );
  private isInARoom$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isInitialized$ = new BehaviorSubject<boolean>(false);
  private reconnection$: Subject<void> = new Subject<void>();
  private socketConnectionId$ = new BehaviorSubject<string>('');
  private allPendingUser$: Observable<PendingUserByRoomMessage[]>;
  constructor(
    private readonly socket: Socket,
    private readonly store: Store,
    private readonly userDataAccess: UserDataAccess
  ) {
    this.startListeningWebsocketEvents();
  }

  public startListeningWebsocketEvents(): void {
    this.initStatusConnection();
    this.handleRoomReconnection();
    // On doit attendre qu'on soit à l'écoute du status de connexion de la socket
    // avant d'appeler la fonction connect sur la socket
    this.isInitialized$.next(true);
  }

  public isConnectedSnapshot(): boolean {
    return this.connectionStatus$.value === ConnectionStatusEnum.CONNECTED;
  }

  public setAccessToken(accessToken: string): void {
    this.socket.ioSocket.auth = { authorization: accessToken };
  }

  public isInitialized(): Observable<boolean> {
    return this.isInitialized$.asObservable();
  }

  public getSocketConnectionId(): Observable<string> {
    return this.socketConnectionId$.asObservable();
  }

  public sendNewPosition(roomId: string, location: LatLngPosition, userId: string): void {
    // permet de ne pas bufferiser les evenements en cas de perte de connexion
    // comme ça on récupère que les nouveaux une fois reconnecté
    const newPosition: GeoLocationSenderMessage = {
      roomId,
      location,
      userId
    };
    console.log(`owner send new position : lat ${newPosition.location.lat} : long ${newPosition.location.lng}`);
    this.socket.ioSocket.volatile.emit(UserEvents.userGeoLocation, newPosition);
  }

  public sendNewMessage(roomId: string, message: string, replyTo?: string): void {
    const body: RoomMessageDtoType = {
      message,
      roomId,
      replyTo
    };
    this.socket.emit(MessageEvents.createRoomMessage, body);
  }

  public handleNewMessage(): Observable<MessageUnionModel> {
    return this.socket.fromEvent<MessageUnionModel>(MessageEvents.createRoomMessage).pipe(
      withLatestFrom(this.userDataAccess.getUserId()),
      concatMap(([newMessage, userId]: [MessageUnionModel, string]) =>
        this.store.dispatch(new NewRoomMessage(userId, newMessage)).pipe(map(() => newMessage))
      )
    );
  }

  public handleReconnection(): Observable<void> {
    return this.reconnection$.asObservable();
  }

  public getAllPendingUser(): Observable<PendingUserByRoomMessage[]> {
    if (!this.allPendingUser$) {
      this.allPendingUser$ = from(this.socket.fromEvent<PendingUserByRoomMessage[]>(RoomEvents.roomGetAllPendingUser)).pipe(shareReplay(1));
    }
    return this.allPendingUser$;
  }

  public refreshToken(accessToken: string): void {
    this.socket.emit(AuthEvents.refreshToken, { accessToken });
  }

  public handleNewUserFromRoom(): Observable<JoinedRoomMessage> {
    return this.socket.fromEvent<JoinedRoomMessage>(RoomEvents.roomJoin).pipe(
      withLatestFrom(this.store.select(UserState.getUserId)),
      filter(([message, userId]: [JoinedRoomMessage, string]) => message.userId !== userId),
      concatMap(([message]: [JoinedRoomMessage, string]) =>
        this.store
          .dispatch([
            new AddConnectedUserToRoom({
              online: true,
              connectedToRoom: true,
              name: message.userName,
              id: message.userId,
              role: message.role,
              color: message.color,
              job: message.job,
              isSpeaking: false,
              ...(message.avatar ? { avatar: message.avatar } : {})
            }),
            new NewUserInRoom(message.userName)
          ])
          .pipe(map(() => message))
      )
    );
  }

  public handleUpdateRoomDescriptionMessage(): Observable<UpdateRoomDescriptionMessage> {
    return this.socket
      .fromEvent<UpdateRoomDescriptionMessage>(RoomEvents.roomUpdateDescription)
      .pipe(switchMap((message) => this.store.dispatch(new UpdateRoomDescription(message.description))));
  }

  public handleUpdateRoomAvatar(): Observable<RoomAvatarUpdatedMessage> {
    return this.socket
      .fromEvent<RoomAvatarUpdatedMessage>(RoomEvents.roomUpdateAvatar)
      .pipe(
        switchMap((message: RoomAvatarUpdatedMessage) =>
          this.store.dispatch([new RoomAvatarUpdated(), new UpdateRoomAvatar(message.avatar)]).pipe(map(() => message))
        )
      );
  }

  public handleUpdateUserAvatar(): Observable<UserAvatarUpdatedMessage> {
    return this.socket
      .fromEvent<UserAvatarUpdatedMessage>(UserEvents.userUpdateAvatar)
      .pipe(
        switchMap((message: UserAvatarUpdatedMessage) =>
          this.store.dispatch([new UpdateMemberAvatar(message.avatar, message.userId)]).pipe(map(() => message))
        )
      );
  }

  public handleBannedUserRoomMessage(): Observable<BannedFromRoomMessage> {
    return this.socket.fromEvent<BannedFromRoomMessage>(RoomEvents.roomBanUser).pipe(
      withLatestFrom(this.store.select(UserState.getUserId)),
      concatMap(([message, currentUserId]: [LeftRoomMessage, string]) => {
        //TODO il faut ici déterminer si on est un utilisateur éphémère ou non pour soit rediriger vers la map,
        // ou rediriger vers la page de login en s'assurant que les cookies refreshToken et
        // access token ont été invalidés. Dans tous les cas il faut fermer toutes les modales ouvertes.
        const banOrLeaveAction = currentUserId === message.userId ? new QuitRoom() : new RemoveUserToRoom(message.userId);
        return this.store.dispatch([banOrLeaveAction, new BannedUserInRoom(message, currentUserId)]).pipe(map(() => message));
      })
    );
  }

  public handleLeftRoomMessage(): Observable<LeftRoomMessage> {
    return this.socket.fromEvent<LeftRoomMessage>(RoomEvents.roomLeave).pipe(
      withLatestFrom(this.store.select(UserState.getUserId)),
      concatMap(([message, currentUserId]: [LeftRoomMessage, string]) => {
        const userLeftAction = currentUserId === message.userId ? new QuitRoom() : new RemoveUserToRoom(message.userId);
        return this.store.dispatch([userLeftAction, new UserLeftRoomSocket(message, currentUserId)]).pipe(map(() => message));
      })
    );
  }

  public handleDisconnectFromRoomMessage(): Observable<DisconnectFromRoomMessage> {
    return this.socket.fromEvent<DisconnectFromRoomMessage>(RoomEvents.roomDisconnect).pipe(
      withLatestFrom(this.store.select(UserState.getUserId)),
      concatMap(([message, currentUserId]: [DisconnectFromRoomMessage, string]) => {
        const userLeftAction = currentUserId === message.userId ? new QuitRoom() : new RemoveConnectedUserToRoom(message.userId);
        return this.store.dispatch([userLeftAction, new UserDisconnectFromRoom(message, currentUserId)]).pipe(map(() => message));
      })
    );
  }

  public handleConnectedRoomDeleted(): Observable<void> {
    return this.socket.fromEvent<void>(RoomEvents.roomDelete).pipe(
      withLatestFrom(this.store.select(RoomSessionState.getRoomName)),
      concatMap(([, roomName]: [void, string]) => this.store.dispatch([new QuitRoom(), new DeletedRoom(roomName)]))
    );
  }

  /**
   * Evenement envoyé au proprio lorsqu'un utilisateur demande l'autorisation de rejoindre un salon.
   */
  public roomPendingOwnerAgreement(): Observable<RoomPendingApprovalOwnerMessage> {
    return this.socket.fromEvent<RoomPendingApprovalOwnerMessage>(RoomEvents.roomPendingOwnerAgreement);
  }

  // /**
  //  * Le proprio répond au serveur si il autorise le nouvel utilisateur
  //  *
  //  * @param isGranted
  //  * @param roomId
  //  * @param userId
  //  */
  // public sendPendingApproval(isGranted: boolean, roomId: string, userId: string): void {
  //   const pendingApproval: RoomPendingApproval = {
  //     isGranted,
  //     roomId,
  //     userId
  //   };
  //   this.socket.emit(RoomEvents.roomPendingAccess, pendingApproval);
  // }

  // TODO renommer
  /**
   * L'utilisateur attend l'approbation du proprio du salon
   */
  public getRoomPendingAccess(): Observable<RoomPendingApprovalMessage> {
    return this.socket
      .fromEvent<RoomPendingApprovalMessage>(RoomEvents.roomPendingAccess)
      .pipe(tap((message: RoomPendingApprovalMessage) => this.isInARoom$.next(message.isGranted)));
  }

  /**
   * next: la liste des utilisateurs dans la room,
   * error: la raison (room introuvable, non autorisé, ...)
   *
   * @param roomId
   * @param password optionnel si l'utilisateur est déjà dans la liste des membres
   */
  public joinRoom(roomId: string, password = ''): Observable<JoinRoomRequestMessage> {
    if (roomId) {
      const joinRoomMessage: JoinRoomDtoType = {
        roomId,
        password
      };

      return emitEventWithCallback<JoinRoomDtoType, ServerErrorMessage, JoinRoomRequestMessage>(
        this.socket,
        RoomEvents.roomSubscribe,
        joinRoomMessage
      ).pipe(tap(({ status }) => this.isInARoom$.next(status === RoomSubscriptionStatus.GRANTED)));
    } else {
      throw new Error('missing room id');
    }
  }

  public getGeoLocationEvent(): Observable<GeoLocationSenderMessage> {
    return this.socket.fromEvent<GeoLocationSenderMessage>(UserEvents.userGeoLocation);
  }

  public connectionStatus(): Observable<ConnectionStatusEnum> {
    return this.connectionStatus$.asObservable();
  }

  public isConnected(): Observable<boolean> {
    return this.connectionStatus$.asObservable().pipe(map((connectionStatus) => connectionStatus === ConnectionStatusEnum.CONNECTED));
  }

  public isInARoom(): Observable<boolean> {
    return this.isInARoom$.asObservable();
  }

  public connect(): void {
    // const accessToken = this.jwtService.getAccessTokenSync();
    // // On rajoute le token de session pour pouvoir gérer l'authentification de la socket
    // this.socket.ioSocket.auth = { authorization: accessToken };
    this.socket.connect();
  }

  public disconnect(): void {
    this.socket.disconnect();
  }

  public sendSdpOffer(offer: WebRtcOfferMessage): Observable<void> {
    return emitEventWithCallback<WebRtcOfferMessage, ServerErrorMessage, Record<string, any>>(
      this.socket,
      AudioRtcEvents.offer,
      offer
    ).pipe(map(() => void 0));
  }

  public sendIceCandidate(candidate: AddIceCandidateMessage): void {
    this.socket.emit(AudioRtcEvents.callerCandidate, candidate);
  }

  public getSdpAnswer(): Observable<WebRtcAnswerMessage> {
    return this.socket.fromEvent<WebRtcAnswerMessage>(AudioRtcEvents.answer);
  }

  public listenCalleeCandidate(callback: (candidate: AddIceCandidateMessage) => void): void {
    this.socket.on(AudioRtcEvents.calleeCandidate, (iceCandidate: AddIceCandidateMessage) => callback(iceCandidate));
  }

  public stopListeningCalleeCandidate(): void {
    this.socket.off(AudioRtcEvents.calleeCandidate);
  }

  public sendUserSpeakingEvent(userSpeaking: UserSpeakingMessage): void {
    this.socket.emit(AudioRtcEvents.userSpeaking, userSpeaking);
  }

  public getUserSpeakingEvent(): Observable<UserSpeakingMessage> {
    return this.socket.fromEvent<UserSpeakingMessage>(AudioRtcEvents.userSpeaking);
  }

  public sendEmergencyAlertEvent(userAlert: UserEmergencyAlertMessage): void {
    this.socket.emit(RoomEvents.roomEmergencyAlert, userAlert);
  }

  public getEmergencyAlertEvent(): Observable<UserEmergencyAlertMessage> {
    return this.socket.fromEvent<UserEmergencyAlertMessage>(RoomEvents.roomEmergencyAlert);
  }

  private initStatusConnection(): void {
    this.socket.on(MiscEvents.connect, () => {
      console.log('socket is connected');
      // on s'enregistre auprès du serveur pour permettre à partir d'un user de pouvoir trouver la bonne socket id et
      // de communiquer en direct
      this.socket.emit(UserEvents.userSubscribe, null, (socketConnectionId: string) => {
        console.log('socket has been successfully authorized');
        // On est considéré connecté uniquement quand la socket est enregistré dans la BDD
        this.connectionStatus$.next(ConnectionStatusEnum.CONNECTED);
        this.socketConnectionId$.next(socketConnectionId);
      });
    });

    this.socket.on(MiscEvents.disconnect, (reason: string) => {
      console.error('socket has been disconnected');
      this.socketConnectionId$.next('');
      this.connectionStatus$.next(ConnectionStatusEnum.DISCONNECTED);
      this.isInARoom$.next(false);
      // On nettoie le state avec la liste des utilisateur connectés
      this.store.dispatch(new ResetConnectedUsersToRoom());

      // si le serveur n'a pas crashé alors on réinitialise le state
      if (reason !== WsDisconnectionReasonConstants.NETWORK_FAILURE) {
        this.store.dispatch(new QuitRoom());
      }
    });

    merge([this.socket.fromEvent<LeftRoomMessage>(RoomEvents.roomLeave), this.socket.fromEvent<LeftRoomMessage>(RoomEvents.roomBanUser)])
      .pipe(
        mergeAll(2),
        withLatestFrom(this.store.select(UserState.getUserId)),
        tap(([message, currentUserId]: [LeftRoomMessage | BannedFromRoomMessage, string]) => {
          if (message.userId === currentUserId) {
            this.isInARoom$.next(false);
          }
        })
      )
      .subscribe();
  }

  private handleRoomReconnection(): void {
    this.socket.ioSocket.io.on(MiscEvents.reconnection, () => {
      this.reconnection$.next();
    });

    this.socket.ioSocket.io.on(MiscEvents.reconnecting, () => {
      this.connectionStatus$.next(ConnectionStatusEnum.CONNECTING);
    });
  }
}

import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RoomSessionState } from '@business/room/communications/data-access/state/room-session-state';
import { FetchRoomMessages, NewRoomMessage } from '@business/message/data-access/state/message.action';
import { MediaModel, MessageType, MessageUnionModel, RoomMessageStatusEventEnum, UserResponse } from '@proxima/common';
import { MessageMapper } from '@business/message/mappers/message.mapper';
import { StateReset } from 'ngxs-reset-plugin';

export interface MessageStateModel {
  /**
   * Arbre associant l'id d'un salon ou d'un autre utilisateur avec la liste des messages
   */
  tree: Record<string, MessageTreeModel>;
}

export interface MessageTreeModel {
  /** ID, message */
  messages: Record<string, Message>;
  /** Ordre des messages selon la date */
  order: string[];
  /** Détermine si on a récupéré les messages les plus anciens */
  isLastPageLoaded: boolean;
  /** Etat du dernier chargement */
  loadingState: LoadingState;
}

export interface BaseMessage {
  id: string;
  roomId: string;
  from: UserResponse;
  replyTo?: MessageUnionModel;
  createdAt: Date;
  updatedAt: Date;
  seen: boolean;
  owner: boolean;
  type: MessageType;
}

export interface TextMessage extends BaseMessage {
  message: string;
}

export interface RoomEventMessage extends BaseMessage {
  message: string;
  statusEvent: RoomMessageStatusEventEnum;
}

export interface AudioMessage extends BaseMessage {
  audio: MediaModel;
}

export interface ImageMessage extends BaseMessage {
  image: MediaModel;
  thumbnail: MediaModel;
  legend?: string;
}

export type Message = TextMessage | ImageMessage | RoomEventMessage | AudioMessage;

export interface MessagesGroupedBySameDay {
  date: Date;
  messages: Message[];
}

const defaultMessageTree: MessageStateModel = { tree: {} };

const MESSAGE_STATE_TOKEN = new StateToken<MessageStateModel>('message');
@State({
  name: MESSAGE_STATE_TOKEN,
  defaults: defaultMessageTree
})
@Injectable()
export class MessageState implements NgxsOnInit {
  constructor(private readonly mapper: MessageMapper) {}
  @Selector()
  static getState(state: MessageState): MessageState {
    return state;
  }

  @Selector()
  static getTree(state: MessageStateModel): Record<string, MessageTreeModel> {
    return state.tree;
  }

  @Selector([MessageState.getTree, RoomSessionState.getRoomId])
  static getCurrentRoomMessages(tree: Record<string, MessageTreeModel>, roomId: string): MessageTreeModel | null {
    return roomId ? tree[roomId] : null;
  }

  @Selector([MessageState.getCurrentRoomMessages])
  static isLastMessagePageLoaded(currentRoomMessages: MessageTreeModel | null): boolean {
    return currentRoomMessages ? currentRoomMessages.isLastPageLoaded : false;
  }

  @Selector([MessageState.getCurrentRoomMessages])
  static getCurrentRoomMessagesNumber(currentRoomMessages: MessageTreeModel | null): number {
    if (!currentRoomMessages) {
      return 0;
    } else {
      return Object.keys(currentRoomMessages.messages).length;
    }
  }

  @Selector([MessageState.getCurrentRoomMessages])
  static getCurrentRoomLastMessage(currentRoomMessages: MessageTreeModel | null): Message | null {
    if (!currentRoomMessages) {
      return null;
    } else {
      const lastItem = currentRoomMessages.order.slice(-1)[0];
      return currentRoomMessages.messages[lastItem];
    }
  }

  @Selector([MessageState.getCurrentRoomMessages])
  static getCurrentRoomMessagesOrderedByDate(currentRoomMessages: MessageTreeModel | null): MessagesGroupedBySameDay[] {
    if (!currentRoomMessages) {
      return [];
    } else {
      // TODO faire en sorte que ça soit le back qui le retourne sous ce format
      const groupByDate = Object.values(currentRoomMessages.messages).reduce(
        (groups, message) => {
          // TODO gérer les locales et afficher les dates aux bon format
          // ex : 13-06-2023
          const date = message.createdAt.toDateString();
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(message);
          return groups;
        },
        {} as Record<string, Message[]>
      );

      // Edit: to add it in the array format instead
      const groupArrays: MessagesGroupedBySameDay[] = Object.keys(groupByDate).map((date) => ({
        date: new Date(date),
        messages: groupByDate[date].sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      }));
      return groupArrays;
    }
  }

  @Action(NewRoomMessage)
  newRoomMessage({ patchState, getState }: StateContext<MessageStateModel>, { currentUserId, newRoomMessage }: NewRoomMessage) {
    const { tree } = getState();
    const roomId = newRoomMessage.roomId;
    const messageTree = tree[roomId];
    if (messageTree) {
      const message: Message = this.mapper.mapMessageUnionModelToMessageModel(currentUserId, newRoomMessage);
      const isMessageAlreadyExist = !!tree[roomId].messages[message.id];

      if (!isMessageAlreadyExist) {
        const newTree = {
          ...tree[roomId],
          messages: { ...tree[roomId].messages },
          order: [...tree[roomId].order]
        };
        newTree.messages[message.id] = message;
        newTree.order.push(message.id);
        patchState({ tree: { ...tree, [roomId]: newTree } });
      }
    }
  }

  @Action(FetchRoomMessages)
  fetchRoomMessages({ patchState, getState }: StateContext<MessageStateModel>, { treeId, fetchedMessages }: FetchRoomMessages) {
    const { tree } = getState();
    patchState({ tree: { ...tree, [treeId]: this.mergeExistingMessageWithFetchNew(tree[treeId], fetchedMessages) } });
  }

  private mergeExistingMessageWithFetchNew(existingTree: MessageTreeModel, fetchedRoomMessages: MessageTreeModel): MessageTreeModel {
    if (fetchedRoomMessages.loadingState !== LoadingState.LOADED) {
      return existingTree;
    }

    let newTree: MessageTreeModel;
    if (existingTree) {
      newTree = { ...existingTree, messages: { ...existingTree.messages }, order: [...existingTree.order] };
      newTree.loadingState = fetchedRoomMessages.loadingState;
      newTree.isLastPageLoaded = fetchedRoomMessages.isLastPageLoaded;
      Object.entries(fetchedRoomMessages.messages).forEach(([messageId, message]) => {
        if (!existingTree.messages[messageId]) {
          newTree.messages[messageId] = message;
          // TODO Ne faut-il pas plutôt ordonner par date ?
          newTree.order.push(messageId);
        }
      }, []);
    } else {
      newTree = fetchedRoomMessages;
    }

    return newTree;
  }

  ngxsOnInit({ dispatch }: StateContext<MessageStateModel>) {
    dispatch(new StateReset(MessageState));
  }
}

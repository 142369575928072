import { Action, createSelector, NgxsOnInit, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AvatarEntityModel, AvatarsStateModel } from '@business/avatar/data-access/state/avatar.model';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { ResetAllAvatar, SetAvatar, SetAvatarLoadingStatus } from '@business/avatar/data-access/state/avatar.actions';
import { StateReset } from 'ngxs-reset-plugin';

const AVATAR_STATE_TOKEN = new StateToken<AvatarsStateModel>('avatar');

const defaultAvatars: AvatarsStateModel = {};

@State({
  name: AVATAR_STATE_TOKEN,
  defaults: defaultAvatars
})
@Injectable()
export class AvatarState implements NgxsOnInit {
  public static get(avatarId: string): (state: AvatarsStateModel) => string | null {
    return createSelector([AvatarState], (state: AvatarsStateModel) => state[avatarId]?.avatarUrl ?? null);
  }

  public static getLoadingState(avatarId: string): (state: AvatarsStateModel) => LoadingState {
    return createSelector([AvatarState], (state: AvatarsStateModel) => state[avatarId]?.state ?? LoadingState.NOT_LOADED);
  }

  public static getAvatarState(avatarId: string): (state: AvatarsStateModel) => AvatarEntityModel {
    return createSelector(
      [AvatarState],
      (state: AvatarsStateModel) => state[avatarId] ?? { avatarUrl: null, state: LoadingState.NOT_LOADED }
    );
  }

  @Action(SetAvatar)
  setAvatar({ getState, patchState }: StateContext<AvatarsStateModel>, { avatarUrl, avatarId }: SetAvatar) {
    const currentAvatars = getState();
    const existingLoadingState = currentAvatars[avatarId]?.state ?? LoadingState.LOADED;
    patchState({ ...currentAvatars, [avatarId]: { avatarUrl, state: existingLoadingState } });
  }

  @Action(SetAvatarLoadingStatus)
  setLoadingAvatar({ getState, patchState }: StateContext<AvatarsStateModel>, { avatarId, loadingState }: SetAvatarLoadingStatus) {
    const currentAvatars = getState();
    const existingAvatarUrl = currentAvatars[avatarId]?.avatarUrl ?? null;
    patchState({ ...currentAvatars, [avatarId]: { avatarUrl: existingAvatarUrl, state: loadingState } });
  }

  @Action(ResetAllAvatar)
  resetAllAvatar({ setState }: StateContext<AvatarsStateModel>) {
    setState({});
  }

  ngxsOnInit({ dispatch }: StateContext<AvatarsStateModel>) {
    dispatch(new StateReset(AvatarState));
  }
}

import { Injectable } from '@angular/core';
import {
  AudioMessageResponseModel,
  EventMessageResponseModel,
  FormattedResponse,
  ImageMessageResponseModel,
  MessageType,
  MessageUnionModel,
  TextMessageResponseModel
} from '@proxima/common';
import {
  AudioMessage,
  ImageMessage,
  Message,
  MessageTreeModel,
  RoomEventMessage,
  TextMessage
} from '@business/message/data-access/state/message-state';
import { LoadingState } from '@shared/models/enums/loading-state.enum';
import { isDefined } from '@shared/typeguard/is-defined.type-guard';

@Injectable()
export class MessageMapper {
  public mapFormattedMessageResponse<T>(res: FormattedResponse<T>): T {
    if (isDefined(res.result)) {
      return res.result;
    }
    throw new Error('result is missing');
  }

  public mapMessageResponseModelToMessageTreeModel(
    currentUserId: string,
    fetchedMessages: MessageUnionModel[],
    isLastPage: boolean
  ): MessageTreeModel {
    const order: string[] = [];
    const messages = fetchedMessages.reduce(
      (acc, current) => {
        const id = current.id;

        order.push(id);
        const message: Message = this.mapMessageUnionModelToMessageModel(currentUserId, current);

        acc[id] = message;
        return acc;
      },
      {} as Record<string, Message>
    );
    return { order, messages, isLastPageLoaded: isLastPage, loadingState: LoadingState.LOADED };
  }

  public mapMessageUnionModelToMessageModel(currentUserId: string, message: MessageUnionModel): Message {
    const baseMessage: Partial<Message> = {
      id: message.id,
      roomId: message.roomId,
      from: message.from,
      type: message.type,
      createdAt: new Date(message.createdAt),
      seen: false,
      updatedAt: new Date(message.updatedAt),
      owner: currentUserId === message.from.id,
      replyTo: message.replyTo
    };
    switch (message.type) {
      case MessageType.TEXT: {
        const mappedMessage: TextMessageResponseModel = message as TextMessageResponseModel;
        return { ...baseMessage, message: mappedMessage.message } as TextMessage;
      }
      case MessageType.IMAGE: {
        const mappedMessage: ImageMessageResponseModel = message as ImageMessageResponseModel;
        return { ...baseMessage, image: mappedMessage.image, thumbnail: mappedMessage.thumbnail, legend: mappedMessage.legend } as ImageMessage;
      }
      case MessageType.EVENT: {
        const mappedMessage: EventMessageResponseModel = message as EventMessageResponseModel;
        return { ...baseMessage, message: mappedMessage.message, statusEvent: mappedMessage.statusEvent } as RoomEventMessage;
      }
      case MessageType.AUDIO: {
        const mappedMessage: AudioMessageResponseModel = message as AudioMessageResponseModel;
        return { ...baseMessage, audio: mappedMessage.audio } as AudioMessage;
      }
    }
  }
}

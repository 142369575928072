export enum MediaBlobResponseStateEnum {
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE'
}

export interface MediaBlobResponse {
    state: MediaBlobResponseStateEnum;
    progress: number;
    content: Blob | null;
}

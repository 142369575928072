import { Injectable } from '@angular/core';
import { PendingUserByRoomMessage, UserResponse, UserRole } from '@proxima/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RoomDataAccess } from '../data-access/room.data-access';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { UserDataAccess } from '@business/user/communications/data-access/user.data-access';

@Injectable()
export class PendingUserService {
  private allPendingUserByOwnedRoom$: Observable<PendingUserByRoomMessage[]>;
  private reloadRequest$ = new BehaviorSubject<void>(void 0);

  constructor(
    private readonly roomDataAccess: RoomDataAccess,
    private readonly userDataAccess: UserDataAccess
  ) {}

  getAllPendingUserByOwnedRoom(): Observable<PendingUserByRoomMessage[]> {
    // TODO problème quand on supprime ou ajoute un salon, la liste des salons ne se met pas à jour
    if (!this.allPendingUserByOwnedRoom$) {
      this.allPendingUserByOwnedRoom$ = this.reloadRequest$.pipe(
        switchMap(() => this.roomDataAccess.getAllPendingUserByOwnedRoom()),
        shareReplay(1)
      );
    }
    return this.allPendingUserByOwnedRoom$;
  }

  getAllPendingUserByOwnerCounter(): Observable<number> {
    return this.userDataAccess.getUserInfo().pipe(
      switchMap((user: UserResponse, index) => {
        if (index > 0) {
          this.reloadAllPendingUserByOwnedRoom();
        }
        if (user.role !== UserRole.ANONYMOUS) {
          return this.getAllPendingUserByOwnedRoom().pipe(
            map((allPendingUser) =>
              allPendingUser.reduce(
                (acc, room) => (Object.keys(room.pendingMembers).length > 0 ? acc + Object.keys(room.pendingMembers).length : acc),
                0
              )
            )
          );
        } else {
          return of(0);
        }
      })
    );
  }

  reloadAllPendingUserByOwnedRoom(): void {
    this.reloadRequest$.next();
  }
}

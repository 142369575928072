import { NgModule } from '@angular/core';
import { MessageMapper } from './mappers/message.mapper';
import { MessageClient } from './communications/client/message.client';
import { MessageDataAccess } from './data-access/message.data-access';
import { FetchMessagesService } from '@business/message/services/fetch-message/fetch-messages.service';
import { UnreadMessageService } from '@business/message/services/unread-message/unread-message.service';
import { NgxsModule } from '@ngxs/store';
import { MessageState } from '@business/message/data-access/state/message-state';

@NgModule({
  imports: [NgxsModule.forFeature([MessageState])],
  providers: [MessageMapper, MessageClient, MessageDataAccess, FetchMessagesService, UnreadMessageService]
})
export class MessageModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { RoomMapper } from '../mappers/room.mapper';
import { Observable } from 'rxjs';
import {
  AddRoomDtoType,
  ConnectionRequestResponseModel,
  FormattedResponse,
  GetRoomByJoinIdDtoType,
  JoinRoomDtoType,
  MediaModel,
  PendingUserAgreementDtoType,
  PendingUserByRoomMessage,
  RoomResponse,
  UpdateDescriptionDtoType,
  UpdateRoomPasswordDtoType,
  UrlUtil
} from '@proxima/common';
import { environment } from '@environments/environment';
import { IS_PUBLIC_API } from '@shared/constants/api-context.constants';
import { map } from 'rxjs/operators';
import { RoomApiUrl } from '../constants/api-url.constants';

@Injectable()
export class RoomClient {
  private headers = {
    'Content-Type': 'application/json'
  };

  constructor(
    private readonly http: HttpClient,
    private readonly roomMapper: RoomMapper
  ) {}

  public getRoomByJoinIdAndPassword(body: GetRoomByJoinIdDtoType): Observable<RoomResponse> {
    return this.http
      .post<FormattedResponse<RoomResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.getRoomById), body, {
        headers: this.headers,
        context: new HttpContext().set(IS_PUBLIC_API, true),
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomResponse(res)));
  }

  public joinRoom(body: JoinRoomDtoType): Observable<boolean> {
    return this.http
      .post<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.joinRoom), body, {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => true));
  }

  public getAuthorizedRoom(): Observable<RoomResponse[]> {
    return this.http
      .get<FormattedResponse<RoomResponse[]>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.getRoomsByMember), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomResponseArray(res)));
  }

  public getAllPendingUserByOwnedRoom(): Observable<PendingUserByRoomMessage[]> {
    return this.http
      .get<FormattedResponse<PendingUserByRoomMessage[]>>(
        UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.getAllPendingUserByOwnedRoom),
        {
          headers: this.headers,
          withCredentials: true
        }
      )
      .pipe(map((res) => this.roomMapper.mapFormattedPendingUserByOwnerResponseArray(res)));
  }

  public getRoomsByPendingMember(): Observable<RoomResponse[]> {
    return this.http
      .get<FormattedResponse<RoomResponse[]>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.getRoomsByPendingMember), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomResponseArray(res)));
  }

  public getOwnedRooms(): Observable<RoomResponse[]> {
    return this.http
      .get<FormattedResponse<RoomResponse[]>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.getOwnedRooms), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomResponseArray(res)));
  }

  public pendingUserAgreement(body: PendingUserAgreementDtoType, roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.pendingUserAgreement.replace('%id', roomId);
    return this.http
      .post<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), body, {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public leaveRoom(roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.leaveRoom.replace('%id', roomId);
    return this.http
      .delete<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public banUser(userId: string, roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.BanUser.replace('%id', roomId);
    return this.http
      .post<FormattedResponse<void>>(
        UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId),
        {
          userId
        },
        {
          headers: this.headers,
          withCredentials: true
        }
      )
      .pipe(map(() => void 0));
  }

  public deleteRoom(roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.deleteRoom.replace('%id', roomId);
    return this.http
      .delete<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public getRoomJoinIdFromOwner(roomId: string): Observable<string> {
    const craftedUrlWithId = RoomApiUrl.getRoomJoinIdFromOwner.replace('%id', roomId);
    return this.http
      .get<FormattedResponse<string>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomJoinIdResponse(res)));
  }

  public getConnectionRequestFromOwner(roomId: string): Observable<ConnectionRequestResponseModel> {
    const craftedUrlWithId = RoomApiUrl.getConnectionRequestFromOwner.replace('%id', roomId);
    return this.http
      .get<FormattedResponse<ConnectionRequestResponseModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomConnectionRequestResponse(res)));
  }

  public getRoomFromConnectionRequest(linkId: string): Observable<RoomResponse> {
    const craftedUrlWithId = RoomApiUrl.getRoomFromConnectionRequest.replace('%id', linkId);
    return this.http
      .get<FormattedResponse<RoomResponse>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomResponse(res)));
  }

  public updateRoomPassword(roomId: string, body: UpdateRoomPasswordDtoType): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.updateRoomPassword.replace('%id', roomId);
    return this.http
      .put<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), body, {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public updateRoomAvatar(roomId: string, avatar: File): Observable<MediaModel> {
    const craftedUrlWithId = RoomApiUrl.updateRoomAvatar.replace('%id', roomId);
    const formData: FormData = new FormData();
    formData.append('avatar', avatar);

    return this.http
      .put<FormattedResponse<MediaModel>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), formData, {
        withCredentials: true
      })
      .pipe(map((res) => this.roomMapper.mapFormattedRoomAvatarResponse(res)));
  }

  public updateDescription(roomId: string, body: UpdateDescriptionDtoType): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.updateRoomDescription.replace('%id', roomId);
    return this.http
      .put<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId), body, {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public createRoom(body: AddRoomDtoType, avatar?: File): Observable<void> {
    const formData: FormData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (avatar) {
      formData.append('avatar', avatar);
    }

    return this.http
      .post<FormattedResponse<void>>(UrlUtil.buildUrl(environment.apiEndpoint, RoomApiUrl.createRoom), formData, {
        withCredentials: true
      })
      .pipe(map(() => void 0));
  }

  public lockRoom(roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.lockRoom.replace('%id', roomId);
    return this.http
      .post<FormattedResponse<void>>(
        UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId),
        {},
        {
          headers: this.headers,
          withCredentials: true
        }
      )
      .pipe(map(() => void 0));
  }

  public unlockRoom(roomId: string): Observable<void> {
    const craftedUrlWithId = RoomApiUrl.unlockRoom.replace('%id', roomId);
    return this.http
      .post<FormattedResponse<void>>(
        UrlUtil.buildUrl(environment.apiEndpoint, craftedUrlWithId),
        {},
        {
          headers: this.headers,
          withCredentials: true
        }
      )
      .pipe(map(() => void 0));
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { DarkMode } from '@aparajita/capacitor-dark-mode';
import { ThemeService } from './app/services/theme-switch/theme.service';
import { StorageService } from './app/services/storage/storage.service';
import { Platform } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { JackDetection } from 'jack-detection';
import { CustomButton } from 'capacitor-custom-button';

if (environment.production) {
  enableProdMode();
}

// Permet d'utiliser le plugin camera de capacitor (permet d'utiliser des web components)
// Call the element loader before the bootstrapModule/bootstrapApplication call
void defineCustomElements(window);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(async (ref) => {
    const storage = ref.injector.get(StorageService);
    await storage.init();
    return ref;
  })
  .then(async (ref) => {
    const platform = ref.injector.get(Platform);
    await platform.ready();
    return ref;
  })
  .then((ref) => {
    const themeService = ref.injector.get(ThemeService);
    void DarkMode.init({
      getter: themeService.getAppearance.bind(themeService),
      setter: themeService.setAppearance.bind(themeService),
      syncStatusBar: true
    });
    return ref;
  })
  .then(() => JackDetection.removeAllListeners())
  .then(() => CustomButton.removeAllListeners())
  .catch((err) => console.error(err));
